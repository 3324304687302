import React from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../../../helpers_components/ErrorFeedback";

export default function SectionOne({ onNextSection, state }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      batch_number: state.batch_number || "",
      pounds_chips_in_fryer: state.pounds_chips_in_fryer || "",
      time_chips_went_in_fryer: state.time_chips_went_in_fryer || "",
      oil_temp_whe_chips_in_fryer: state.oil_temp_whe_chips_in_fryer || "",
    },
    validationSchema: Yup.object({
      batch_number: Yup.string().required("This is a required field"),
      pounds_chips_in_fryer: Yup.string().required("This is a required field"),
      time_chips_went_in_fryer: Yup.string().required("This is a required field"),
      oil_temp_whe_chips_in_fryer: Yup.number().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SectionOne_Fryer", values);
      onNextSection("section_1", values);
    },
  });

  const submit = (event) => {
    event.preventDefault();
    val
      .validateForm()
      .then((r) => {        
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }
        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    val.resetForm();
  };

  return (
    <Form onSubmit={submit}>
      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Batch Number <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="batch_number"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.batch_number || ""}
            isInvalid={val.errors.batch_number ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.batch_number} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Pounds Of Chips In The Fryer <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="pounds_chips_in_fryer"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.pounds_chips_in_fryer || ""}
            isInvalid={val.errors.pounds_chips_in_fryer ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.pounds_chips_in_fryer} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Time The Chips Went In The Fryer <span>*</span>
          </h2>
          <Form.Control
            type="time"
            name="time_chips_went_in_fryer"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.time_chips_went_in_fryer || ""}
            isInvalid={val.errors.time_chips_went_in_fryer ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.time_chips_went_in_fryer} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Oil Temp When Chips Went In The Fryer <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="oil_temp_whe_chips_in_fryer"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.oil_temp_whe_chips_in_fryer || ""}
            isInvalid={val.errors.oil_temp_whe_chips_in_fryer ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.oil_temp_whe_chips_in_fryer} />
        </Card.Body>
      </Card>

      <Row>
        <Col md="1">
          <Button
            type="submit"
            size="sm"
            className="fs_c_20 "
            style={{
              padding: "5px 20px",
              boxShadow:
                "0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.141), 0 1px 3px 0 rgba(0, 0, 0, 0.122)",
              backgroundColor: "#fff",
              color: "rgb(0, 144, 176)",
            }}
            variant="light"
          >
            Next
          </Button>
        </Col>
        <Col md="8"></Col>
        <Col md="3">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 "
            style={{ padding: "5px 20px", background: "none", color: "rgb(0, 144, 176)", border: "none" }}
            variant="light"
            onClick={clearForm}
          >
            Clear Form
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
