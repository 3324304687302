import React from "react";
import { Form, InputGroup, Row, Button } from "react-bootstrap";

import { COLORS } from "../../../config";

export default function TabDataSeasoningDailyLog({ handleChangeFlavor, state }) {
  return (
    <>
      <Row className="">
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Flavor Start Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="time"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="flavor_start_time"
            data-handle4={state.id}
            value={state.flavor_start_time}
          />
          <Button
            data-handle1="flavors"
            data-handle2="clear"
            data-handle3="flavor_start_time"
            data-handle4={state.id}
            onClick={handleChangeFlavor}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Singles Completed:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="singles_completed"
            data-handle4={state.id}
            value={state.singles_completed}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Refills Completed:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="refills_completed"
            data-handle4={state.id}
            value={state.refills_completed}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Were all bags Kitted:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="were_all_bags_kitted"
            data-handle4={state.id}
            value={state.were_all_bags_kitted}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Number of bags left to kit :</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="number_of_bags_left_to_kit"
            data-handle4={state.id}
            value={state.number_of_bags_left_to_kit}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text className="fs_c_20">Flavor End Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="time"
            style={{ color: COLORS.LabelData }}
            onChange={handleChangeFlavor}
            data-handle1="flavors"
            data-handle3="flavor_end_time"
            data-handle4={state.id}
            value={state.flavor_end_time}
          />
          <Button
            data-handle1="flavors"
            data-handle2="clear"
            data-handle3="flavor_end_time"
            data-handle4={state.id}
            onClick={handleChangeFlavor}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
      </Row>
    </>
  );
}
