import React, { useEffect, useState } from "react";
import { Col, Table, Row, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { COLORS, HEAD_TABLE_OIL_USED } from "../../config";

export default function PopupOilUsed({
  asBlock,
  oil_used,
  bucket_weight_oil,
  bucket_weight_oil_verified,
  closeModal,
  handleSaveDataFromPopups,
  handleChangeCustom,
}) {
  const [oilUsed, setOilUsed] = useState(oil_used || []);
  const [oil_input, set_oil_input] = useState(0);

  useEffect(() => {
    setOilUsed(oil_used);
  }, [oil_used]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2");
    if (!attr1) {
      attr1 = target.parentNode.getAttribute("data-handle1");
    }
    if (!attr2) {
      attr2 = target.parentNode.getAttribute("data-handle2");
    }

    let value = target.value;

    console.log(attr1, attr2, value);

    if (attr1 === "edit") {
      setOilUsed((pS) =>
        pS.map((et) => {
          if (et.name === attr2) {
            return { ...et, value: value };
          }
          return et;
        })
      );
    }

    if (attr1 === "delete") {
      setOilUsed((pS) =>
        pS.filter((emp) => emp.name !== attr2).map((rt, i) => ({ ...rt, name: `Oil Weight: ${i + 1}` }))
      );
    }
  };

  const onAddBucket = () => {
    if (!oil_input) return;

    setOilUsed((pS) => {
      const newBucket = { name: `Oil Weight ${pS.length + 1}`, value: oil_input };
      return [...pS, newBucket];
    });
  };

  const saveChanges = () => {
    handleSaveDataFromPopups("oil_used", oilUsed);
  };

  const totalOilWeight = oilUsed && oilUsed.reduce((acc, v) => acc + +v.value, 0).toFixed(0);

  return (
    <div className={asBlock ? "" : "popup_container"}>
      <div className={`block ${asBlock ? "daily" : ""}`}>
        {!asBlock && (
          <div className="close_button" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
          </div>
        )}
        <div className="fs_c_30 text-center">Oil Used</div>

        <Row>
          <Col>
            <InputGroup className="mb-4">
              <InputGroup.Text className="fs_c_20">Oil Weight Total:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                disabled
                type="number"
                style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
                value={totalOilWeight}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-4">
              <InputGroup.Text className="fs_c_20">Bucket Weight:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                disabled
                type="number"
                style={{ color: COLORS.LabelData, textAlign: "end" }}
                value={bucket_weight_oil}
              />
              <Button
                onClick={handleChangeCustom}
                className="button_verify"
                data-handle="bucket_weight_oil_verified"
                variant={!bucket_weight_oil_verified ? "danger" : "light"}
              >
                {!bucket_weight_oil_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
          </Col>
        </Row>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Add Oil Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle="partial_bucket_weight"
            onChange={(e) => set_oil_input(e.target.value)}
            value={oil_input}
          />
          <Button variant="outline-primary" size="lg" className="mr-1" onClick={onAddBucket}>
            <span className="fs_c_50">+</span>
          </Button>
        </InputGroup>

        <Table bordered hover>
          <thead>
            <tr>
              {HEAD_TABLE_OIL_USED.map((th) => (
                <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {oilUsed.map((st, ind) => (
              <tr key={st.name}>
                <td className="clean_ups_title">{st.name}</td>
                <td>
                  <InputGroup className="">
                    <Form.Control
                      className="fs_c_30"
                      data-handle1="edit"
                      data-handle2={st.name}
                      type="text"
                      style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
                      value={st.value}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </td>
                <td
                  data-handle1="delete"
                  data-handle2={st.name}
                  onClick={handleChange}
                  style={{ textAlign: "center", fontSize: 18 }}
                >
                  <FontAwesomeIcon
                    data-handle1="delete"
                    data-handle2={st.name}
                    icon={faTrash}
                    size="xl"
                    style={{ color: "#ff0000", padding: 10 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* {!asBlock && ( */}
        <Button
          size="lg"
          className={asBlock ? "" : `save_button mt-3`}
          style={{ padding: "10px 20px" }}
          onClick={saveChanges}
        >
          Save Oil Used
        </Button>
        {/* )} */}
      </div>
    </div>
  );
}
