import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDashboardFlavorsPack } from "../../utils/api";
import Flavors from "./Screens/Flavors";
import { isEmptyFlavorValues } from "../../utils/utils";

export default function Dashboard() {
  const interval = useRef();
  let location = useLocation();

  const [state, setState] = useState({ data: [], estate: true, isLoading: false, columns: [] });

  useEffect(() => {
    getData();
    interval.current = setInterval(getData, 5000);
    return () => {
      clearInterval(interval.current);
    };
  }, [location.pathname]);
  const getData = () => {
    setState((pS) => ({ ...pS, isLoading: true }));
    getDashboardFlavorsPack()
      .then((r) => {
        // console.log(r);
        const isEstate = location.pathname === "/dashboard-estate-inventory";

        let dataEstate = {};
        let dataLimited = {};

        Object.keys(r).forEach((flavor) => {
          if (r[flavor].estate) {
            dataEstate[flavor] = r[flavor];
          } else {
            if (!isEmptyFlavorValues(r[flavor])) {
              dataLimited[flavor] = r[flavor];
            }           
          }
        });

        const fls = Object.keys(r);
        const columns = Object.keys(r[fls[0]]);
        const cl = columns.filter((c) => c !== "color" && c !== "estate").reverse();
        cl.unshift("ESTATE FLAVOR");

        setState((pS) => ({
          ...pS,
          isLoading: false,
          data: isEstate ? dataEstate : dataLimited,
          estate: isEstate,
          columns: cl,
        }));
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Flavors columns={state.columns} data={state.data} estate={state.estate} isLoading={state.isLoading} />
    </>
  );
}
