import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { cloneDeep } from "lodash";

import { COMMON_OPTIONS, chartData } from "../../../utils/utils";

import LoaderFull from "../../../helpers_components/Loader";

import { getStateStationDashboard } from "../../../utils/api";

const thead = [
  "Start Station",
  "Active Employee",
  "Break Employee",
  "Bucket Goal",
  "Actual Buckets",
  "Buckets",
  "Partial Bucket",
  "First Bucket",
  "Final Bucket",
  "End Station",
];

export default function Raw({ from, to }) {
  const [state, setState] = useState({
    loading: false,
    DATA: [],
  });

  useEffect(() => {
    setState({ ...state, loading: true });

    if (!from && !to) {
      getStateStationDashboard("raw")
        .then((r) => {
          console.log(r);
          setState({ ...state, DATA: r.states });
        })
        .catch((e) => {
          console.log(e);
          setState({ ...state, loading: false });
        });
    }

    if (!from || !to) {
      return;
    }

    getStateStationDashboard("raw", from, to)
      .then((r) => {
        console.log(r);
        setState({ ...state, DATA: r.states });
      })
      .catch((e) => {
        console.log(e);
        setState({ ...state, loading: false });
      });
  }, [from, to]);

  const recordsOptions = (records) => {
    const resp = cloneDeep(COMMON_OPTIONS(records));
    return resp;
  };

  return (
    <div className="dashboard_section">
      <h2 className="title">RAW</h2>
      <div style={{ height: 300 }}>
          <Line data={chartData(state.DATA, "raw")} options={recordsOptions(state.DATA)} />
        </div>
      <div style={{ height: 400, overflow: "auto", position: "relative" }}>
        <LoaderFull show={state.loading} stylesCustom={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
       
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              {thead.map((th) => (
                <th key={th}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.DATA.map((s) => (
              <tr key={s.start}>
                <td>{s.start}</td>
                <td>{s.state.active_employee?.name}</td>
                <td>{s.state.break_employee?.name}</td>
                <td>{s.state.bucket_goal}</td>
                <td>{s.state.actual_buckets}</td>
                <td>{s.state.bucket_fryer}</td>
                <td>{s.state.partial_bucket}</td>
                <td>{s.state.first_bucket}</td>
                <td>{s.state.final_bucket}</td>
                <td>{s.end}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
