import React, { useState } from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../../../helpers_components/ErrorFeedback";

import { COLORS, yes_no_options, crateNumbers, potato_conditions_checkbox } from "../../../config";

export default function SectionOne({ onNextSection, state }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      crate_number: state.crate_number || "",
      potato_condition: state.potato_condition ||  "",
      potato_condition_other: state.potato_condition_other ||  "",
      starting_weight: state.starting_weight ||  "",
      ending_weight: state.ending_weight ||  "",
      is_crate_empty: state.is_crate_empty ||  "",
      is_save_potatoes_for_tomorrow: state.is_save_potatoes_for_tomorrow ||  "",
    },
    validationSchema: Yup.object({
      crate_number: Yup.string().required("This is a required field"),
      potato_condition: Yup.string().required("This is a required field"),
      potato_condition_other: Yup.string().when("potato_condition", {
        is: "Other",
        then: () => Yup.string().required("This is a required field"),
        otherwise: () => Yup.string(),
      }),
      starting_weight: Yup.number()
        .min(155, "The Weight you have entered is to low")
        .required("This is a required field"),
      ending_weight: Yup.number()
        .min(125, "The Weight you have entered is to low")
        .required("This is a required field"),
      is_crate_empty: Yup.string().required("This is a required field"),
      is_save_potatoes_for_tomorrow: Yup.string().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SectionOne_Crate", values);
      onNextSection("section_1", values);
    },
  });

  const submit = (event) => {
    event.preventDefault();

    val
      .validateForm()
      .then((r) => {
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }
        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    val.resetForm();
  };

  return (
    <Form onSubmit={submit}>
      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Crate Number <span>*</span>
          </h2>
          <Form.Select
            className="fs_c_20 "
            style={{ color: COLORS.Dropdown }}
            name="crate_number"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.crate_number || ""}
            isInvalid={val.errors.crate_number ? true : false}
          >
            <option value={""}>Choose</option>
            {crateNumbers.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
          <ErrorFeedback error={val.errors.crate_number} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Potato Condition <span>*</span>
          </h2>
          {potato_conditions_checkbox.map((em) => {
            return (
              <div key={em} className="mb-3">
                <Form.Check
                  type="radio"
                  label={em}
                  className="position-relative"
                  name="potato_condition"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  checked={val.values.potato_condition === em}
                  value={em}
                  isInvalid={val.errors.potato_condition ? true : false}
                  id={em}
                />
                {em === "Other" && val.values.potato_condition === "Other" && (
                  <div className="form_at_check">
                    <Form.Control
                      type="text"
                      className="customInputField"
                      name="potato_condition_other"
                      onChange={val.handleChange}
                      onBlur={val.handleBlur}
                      value={val.values.potato_condition_other || ""}
                      isInvalid={val.errors.potato_condition_other ? true : false}
                      id={"potato_condition_other"}
                    ></Form.Control>
                    <ErrorFeedback error={val.errors.potato_condition_other} styleCustom={{ position: "absolute" }} />
                  </div>
                )}
              </div>
            );
          })}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Starting Weight <span>*</span>
          </h2>

          <Form.Control
            type="number"
            name="starting_weight"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.starting_weight || ""}
            isInvalid={val.errors.starting_weight ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.starting_weight} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Ending Weight <span>*</span>
          </h2>
          <Form.Control
            type="number"
            style={{ color: COLORS.NumberPad }}
            name="ending_weight"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.ending_weight || ""}
            isInvalid={val.errors.ending_weight ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.ending_weight} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Is the crate empty <span>*</span>
          </h2>
          <Form.Group>
            {yes_no_options.map((em) => (
              <div key={`is_crate_empty_${em.name}`} className="mb-3">
                <Form.Check
                  type="radio"
                  label={`${em.name}`}
                  name="is_crate_empty"
                  onChange={val.handleChange}
                  onBlur={val.handleBlur}
                  value={em.name}
                  checked={val.values.is_crate_empty === em.name}
                  isInvalid={val.errors.is_crate_empty ? true : false}
                  id={`is_crate_empty_${em.name}`}
                />
              </div>
            ))}
          </Form.Group>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Did you save potatoes for tomorrow? <span>*</span>
          </h2>
          {yes_no_options.map((em) => (
            <div key={`is_save_potatoes_for_tomorrow_${em.name}`} className="mb-3">
              <Form.Check
                type="radio"
                label={em.name}
                name="is_save_potatoes_for_tomorrow"
                onChange={val.handleChange}
                onBlur={val.handleBlur}
                value={em.name}
                checked={val.values.is_save_potatoes_for_tomorrow === em.name}
                isInvalid={val.errors.is_save_potatoes_for_tomorrow ? true : false}
                id={`is_save_potatoes_for_tomorrow_${em.name}`}
              />
            </div>
          ))}
        </Card.Body>
      </Card>

      <Row>
        <Col md="1">
          <Button
            type="submit"
            size="sm"
            className="fs_c_20 "
            style={{
              padding: "5px 20px",
              boxShadow:
                "0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.141), 0 1px 3px 0 rgba(0, 0, 0, 0.122)",
              backgroundColor: "#fff",
              color: "rgb(0, 144, 176)",
            }}
            variant="light"
          >
            Next
          </Button>
        </Col>
        <Col md="8"></Col>
        <Col md="3">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 "
            style={{ padding: "5px 20px", background: "none", color: "rgb(0, 144, 176)", border: "none" }}
            variant="light"
            onClick={clearForm}
          >
            Clear Form
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
