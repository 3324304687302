import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";

export default function Header({ title, toggleModal }) {
  return (
    <div className="header">
      <div className="fs_c_40 text-center mb-4">{title}</div>
      <Button onClick={()=>toggleModal('sign_in_out')} size="sm" className="fs_c_20 " variant="primary">
        Sign In/Out
      </Button>
    </div>
  );
}
