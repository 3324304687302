import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLORS, PINS, MANAGER_INIT, STATIONS, periods } from "../config";
import {
  getStateStation,
  getSettings,
  getStationShiftsListPerDay,
  updateStateStation,
  createStationShift,
  deleteStationShift,
  getStateStationDashboard,
  getEmployees,
} from "./api";

import shopify_img from "../assets/images/shopify.png";
import faire_img from "../assets/images/faire.png";

export function getSourceImage(source) {
  let src = shopify_img;
  if (source === "Shopify") {
    src = shopify_img;
  } else {
    src = faire_img;
  }

  return src;
}

export function generateUniqID(params) {
  return "UUID_" + Date.now().toString(36) + Math.random().toString(36);
}

export function getHeight() {
  const header = document.getElementById("header");
  const nav_1 = document.getElementById("nav_1");
  const nav_2_footer = document.getElementById("nav_2_footer");
  // const nav_2_footer = document.getElementById("nav_2_footer");
  // const nav_2_footer = document.getElementById("nav_2_footer");

  const OBJ_INFO = {
    heightHeader: header.offsetHeight,
    heightNav_1: nav_1?.offsetHeight || 0,
    heightNav_2_footer: nav_2_footer.offsetHeight,
  };

  // console.log("OBJ_INFO", OBJ_INFO);

  const HEIGHT = window.innerHeight;
  // console.log("HEIGHT", HEIGHT);

  return HEIGHT - (OBJ_INFO.heightHeader + OBJ_INFO.heightNav_1 + OBJ_INFO.heightNav_2_footer + 100 + 60);
}

export function getHeighKitting() {
  const header = document.getElementById("header");
  const nav_1 = document.getElementById("nav_1");
  const nav_2_footer = document.getElementById("nav_2_footer");
  // const nav_2_footer = document.getElementById("nav_2_footer");
  // const nav_2_footer = document.getElementById("nav_2_footer");
  if (!header) {
    return 730;
  }
  const OBJ_INFO = {
    heightHeader: header.offsetHeight,
    heightNav_1: nav_1?.offsetHeight || 0,
    heightNav_2_footer: nav_2_footer?.offsetHeight,
  };

  // console.log("OBJ_INFO", OBJ_INFO);

  const HEIGHT = window.innerHeight;
  // console.log("HEIGHT", HEIGHT);

  return HEIGHT - (OBJ_INFO.heightHeader + OBJ_INFO.heightNav_1 + OBJ_INFO.heightNav_2_footer + 70);
}

export const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const isDeepEqual = (object1, object2) => {
  if (!object1 && !object2) {
    return true;
  }

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = typeof value1 === "object" && typeof value2 === "object";

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

export function deepModify(target, source) {
  const result = { ...target }; // Create a new object to preserve the original data

  for (const key in source) {
    if (typeof source[key] === "object" && !Array.isArray(source[key])) {
      if (!result[key] || typeof result[key] !== "object" || Array.isArray(result[key])) {
        result[key] = {};
      }
      result[key] = deepModify(result[key], source[key]);
    } else {
      result[key] = source[key];
    }
  }

  return result;
}

export function filterEmpl(emplBase, alreadyStored) {
  if (!Array.isArray(emplBase) || !Array.isArray(alreadyStored)) {
    return [];
  }

  let newArrEmpl = [];
  for (let i = 0; i < emplBase.length; i++) {
    const emplBaseElement = emplBase[i];
    let is;
    for (let y = 0; y < alreadyStored.length; y++) {
      const alreadyStoredElement = alreadyStored[y];
      if (emplBaseElement.value === alreadyStoredElement.value) {
        is = true;
      }
    }

    if (!is) {
      newArrEmpl.push(emplBaseElement);
    }
  }

  return newArrEmpl;
}

export async function getAPI_per_station(station) {
  return new Promise((res, rej) => {
    const allPromises = [
      new Promise((res, rej) => {
        try {
          res(getStateStation(station));
        } catch (error) {
          rej(error);
        }
      }),
      new Promise((res, rej) => {
        try {
          res(getSettings());
        } catch (error) {
          rej(error);
        }
      }),
    ];

    Promise.all(allPromises)
      .then((r) => {
        // console.log(r);
        const empl = r[1].settings.productionEmployee.assignedEmployees.map((e) => ({
          value: e.value,
          name: e.name,
          status: e.status || "",
        }));

        res({ empl, station: r[0], settings: r[1].settings });
      })
      .catch((e) => rej(e));
  });
}

export async function getAPI_per_station_manager(station, date, shift) {
  return new Promise((res, rej) => {
    const allPromises = [
      new Promise((res, rej) => {
        try {
          res(getStationShiftsListPerDay(station, date));
        } catch (error) {
          rej(error);
        }
      }),
      new Promise((res, rej) => {
        try {
          res(getStateStation(station, date, shift));
        } catch (error) {
          rej(error);
        }
      }),
    ];

    Promise.all(allPromises)
      .then((r) => {
        res({ shifts: r[0], station: r[1] });
      })
      .catch((e) => {
        console.log(e);
        rej(e);
      });
  });
}

export async function get_data_per_station(station, date, shift) {
  return new Promise((res, rej) => {
    const allPromises = [
      new Promise((res, rej) => {
        try {
          res(getStationShiftsListPerDay(station, date));
        } catch (error) {
          rej(error);
        }
      }),
      new Promise((res, rej) => {
        try {
          res(getStateStation(station, date, shift));
        } catch (error) {
          rej(error);
        }
      }),
      new Promise((res, rej) => {
        try {
          res(getEmployees());
        } catch (error) {
          rej(error);
        }
      }),
    ];

    Promise.all(allPromises)
      .then((r) => {
        res({ shifts: r[0], station: r[1], empl: r[2] });
      })
      .catch((e) => {
        console.log(e);
        rej(e);
      });
  });
}

export function validationCode(flavorCode = "01", fullCode) {
  // console.log("fullCode", flavorCode, fullCode);

  const D = new Date();
  // console.log(D);
  const year = `${D.getFullYear()}`.slice(3); // (Single digit year)
  const hours = D.getHours();
  const day = D.getDate() < 10 ? `0${D.getDate()}` : `${D.getDate()}`; //(2 digit day)
  const month = D.getMonth() + 1 < 10 ? `0${D.getMonth() + 1}` : `${D.getMonth() + 1}`; // (2 digit month).

  let shiftType = "M"; // (M for Morning, A for afternoon, E for evening ?,  N for night)

  // if (hours >= 0 && hours < 6) {
  //   shiftType = "N";
  // }
  if (hours >= 8 && hours < 17) {
    shiftType = "M";
  }
  if (hours >= 17 || hours < 2) {
    shiftType = "A";
  }
  // if (hours >= 18 && hours <= 23) {
  //   shiftType = "E";
  // }

  const fullCodeVerify = `${flavorCode}-${month}${day}${year}-${shiftType}`; //01-03083-M

  console.log("fullCodeVerify", fullCodeVerify);

  if (fullCode === fullCodeVerify) {
    return true;
  }
  return false;
}

export function getPeriodDefault(dateNow = new Date()) {
  const year = dateNow.getFullYear();
  const day = dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : `${dateNow.getDate()}`;
  const monthF = (from) => {
    const mD = dateNow.getMonth() + 1;
    if (mD < 10) {
      return `0${mD + (from ? -1 : 0)}`;
    } else {
      return mD + (from ? -1 : 0);
    }
  };

  return { from: `${year}-${monthF(true)}-${day}`, to: `${year}-${monthF(false)}-${day}` };
}

export const chartData = (records, station) => {
  // console.log("records", records);

  let labels = [];
  let bucket_fryer = [];
  let fryer1 = [];
  let fryer2 = [];
  let fryer3 = [];
  let fryer4 = [];
  let fryer5 = [];
  let fryer6 = [];

  let bucketGoal = [];
  let actualBuckets = [];

  let totalCambrios = [];
  let numberBuckets = [];
  let finalBucketWeight = [];

  records.forEach((ir) => {
    labels.unshift(ir.start);

    bucket_fryer.unshift(ir.state?.bucket_fryer ? ir.state?.bucket_fryer : null);
    bucketGoal.unshift(ir.state.bucket_goal);
    actualBuckets.unshift(ir.state.actual_buckets);

    totalCambrios.unshift(ir.state.totalCambrios);
    numberBuckets.unshift(ir.state.number_buckets);
    finalBucketWeight.unshift(ir.state.final_bucket_weight);

    if (ir.state.fryers) {
      ir.state.fryers.forEach((f, ind) => {
        const name = "fryer" + (ind + 1);
        // console.log(name);

        if (name === "fryer1") {
          fryer1.unshift(f.temp);
        }
        if (name === "fryer2") {
          fryer2.unshift(f.temp);
        }
        if (name === "fryer3") {
          fryer3.unshift(f.temp);
        }
        if (name === "fryer4") {
          fryer4.unshift(f.temp);
        }
        if (name === "fryer5") {
          fryer5.unshift(f.temp);
        }
        if (name === "fryer6") {
          fryer6.unshift(f.temp);
        }
      });
    }
  });

  let data = [];

  if (station === "fryer") {
    data.push({
      label: "Fryer 1",
      data: fryer1,
      fill: false,
      borderColor: COLORS.fryer1,
      backgroundColor: COLORS.fryer1,
      type: "bar",
    });
    data.push({
      label: "Fryer 2",
      data: fryer2,
      fill: false,
      borderColor: COLORS.fryer2,
      backgroundColor: COLORS.fryer2,
      type: "bar",
    });
    data.push({
      label: "Fryer 3",
      data: fryer3,
      fill: false,
      borderColor: COLORS.fryer3,
      backgroundColor: COLORS.fryer3,
      type: "bar",
    });
    data.push({
      label: "Fryer 4",
      data: fryer4,
      fill: false,
      borderColor: COLORS.fryer4,
      backgroundColor: COLORS.fryer4,
      type: "bar",
    });
    data.push({
      label: "Fryer 5",
      data: fryer5,
      fill: false,
      borderColor: COLORS.fryer5,
      backgroundColor: COLORS.fryer5,
      type: "bar",
    });
    data.push({
      label: "Fryer 6",
      data: fryer6,
      fill: false,
      borderColor: COLORS.fryer6,
      backgroundColor: COLORS.fryer6,
      type: "bar",
    });
  }
  if (station === "raw") {
    data.push({
      label: "Buckets Goal",
      data: bucketGoal,
      fill: false,
      borderColor: COLORS.fryer1,
      backgroundColor: COLORS.fryer1,
      type: "bar",
    });
    data.push({
      label: "Buckets to fryer",
      data: bucket_fryer,
      fill: false,
      borderColor: COLORS.fryer2,
      backgroundColor: COLORS.fryer2,
      type: "bar",
    });
    data.push({
      label: "Actual Buckets",
      data: actualBuckets,
      fill: false,
      borderColor: COLORS.fryer3,
      backgroundColor: COLORS.fryer3,
      type: "bar",
    });
  }
  if (station === "qa") {
    data.push({
      label: "Total Combrios",
      data: totalCambrios,
      fill: false,
      borderColor: COLORS.fryer1,
      backgroundColor: COLORS.fryer1,
      type: "bar",
    });
    data.push({
      label: "Number buckets",
      data: numberBuckets,
      fill: false,
      borderColor: COLORS.fryer2,
      backgroundColor: COLORS.fryer2,
      type: "bar",
    });
    data.push({
      label: "Final Bucket weight",
      data: finalBucketWeight,
      fill: false,
      borderColor: COLORS.fryer3,
      backgroundColor: COLORS.fryer3,
      type: "bar",
    });
  }

  return {
    labels: labels,
    datasets: data,
  };
};

export const COMMON_OPTIONS = (records) => {
  const resp = {
    responsive: true,
    maintainAspectRatio: false,

    // legend: {
    //   display: false,
    // },
    // scales: {
    //   x: [
    //     {
    //       type: 'time',
    //       stacked: true,
    //       display: true,
    //       labels: records.map((item, i) => ""),
    //       ticks: {
    //         callback: (value, index, values) => {
    //           return value;
    //         },
    //         autoSkip: false,
    //         minRotation: 90,
    //         maxRotation: 90,
    //         fontSize: 11,
    //         padding: 3,
    //       },
    //       gridLines: {
    //         drawBorder: true,
    //         drawTicks: false,
    //         lineWidth: 1,
    //       },
    //     },
    //   ],
    //   y: [
    //     {
    //       // stacked: true,
    //       type: "linear",
    //       id: "y-axis-1",
    //       display: true,
    //       position: "left",
    //       labels: {
    //         show: false,
    //       },
    //       ticks: {
    //         autoSkip: true,
    //         stepSize: 1,
    //         maxTicksLimit: 8,
    //         minRotation: 0,
    //         maxRotation: 0,
    //         fontSize: 10,
    //         padding: 3,
    //       },
    //       gridLines: {
    //         drawBorder: true,
    //         drawTicks: true,
    //         lineWidth: 1,
    //         tickMarkLength: 2,
    //       },
    //       afterFit: (scaleInstance) => {
    //         scaleInstance.width = 45;
    //         scaleInstance.paddingRight = 17;
    //       },
    //     },
    //   ],
    // },
    // animation: {
    //   duration: 0,
    // },
    // hover: {
    //   animationDuration: 0,
    // },
    // responsiveAnimationDuration: 0,
    // showLines: true,
    // point: {
    //   radius: 0,
    // },
    // fill: false,
    // spanGaps: true,
    // annotation: {
    //   annotations: [{}],
    // },
    // tooltips: {
    //   enabled: true,
    //   callbacks: {
    //     label: (t, d) => {
    //       return `${d.datasets[t.datasetIndex].label}: ${Number(t.value).toLocaleString("de", {
    //         maximumFractionDigits: 2,
    //       })}`;
    //     },
    //     title: (t) => labelDate(records[t[0].index].date, period, null, true),
    //   },
    // },
  };

  // if (range) {
  //   (range.min || range.min === 0) && (resp.scales.yAxes[0].ticks.min = range.min);
  //   range.max && (resp.scales.yAxes[0].ticks.max = range.max);
  // }
  // if (avg) {
  //   resp.annotation.annotations[0] = {
  //     type: "line",
  //     scaleID: "y-axis-1",
  //     mode: "horizontal",
  //     value: avg,
  //     borderWidth: 1,
  //     borderColor: "red",
  //     label: {
  //       enabled: true,
  //       content: avg.toFixed(2),
  //       position: "left",
  //       backgroundColor: "transparent",
  //       fontColor: "red",
  //       fontStyle: "normal",
  //       yPadding: 0,
  //       yAdjust: 10,
  //     },
  //   };
  // }

  return resp;
};

export const statusF = (status) => {
  const st = { color: "#ff0000", text: "Empty" };
  if (status === "empty" || status === "") {
    st.color = "#ff0000";
    st.text = "Empty";
  }
  if (status === "in use" || status === "") {
    st.color = "#00ff00";
    st.text = "In Use";
  }

  return st;
};

export const verificationManagerCode = (code, employee) => {
  if (code !== employee.code) {
    return false;
  } else {
    return true;
  }
};

export const getEmployeeCode = (value) => {
  const empl = { ...PINS.find((em) => em.name === value) };
  return empl;
};

export const STATUSES = {
  passed: { icon: faCircleCheck, color: "#16A679", title: "Was started" },
  waiting: { icon: faClock, color: "#B77E0B", title: "Waiting" },
  missed: { icon: faBan, color: "#C5280C", title: "Was not started" },
};

export const detailsStatus = (status) => {
  let currentSt = STATUSES["missed"];
  if (!status || status === "null") {
    currentSt = STATUSES["missed"];
  }
  if (["passed", "waiting", "missed"].includes(status)) {
    currentSt = STATUSES[status];
  }

  return (
    <div className="d-flex align-items-center ms-4">
      <FontAwesomeIcon icon={currentSt.icon} style={{ width: 20, height: 20, color: currentSt.color }} />
      <p className="m-0 ms-2">{currentSt.status ? currentSt.status : currentSt.title}</p>
    </div>
  );
};

export const getMinutesFromInput = (inTime, outTime) => {
  if (inTime === "" || outTime === "") {
    return 0;
  }

  const h_in = +inTime.slice(0, 2);
  const min_in = +inTime.slice(3);

  const h_out = +outTime.slice(0, 2);
  const min_out = +outTime.slice(3);

  const minutes_spend = h_out * 60 + min_out - (h_in * 60 + min_in);
  console.log("minutes_spend", minutes_spend);
  return minutes_spend;
};

export function isCompleteCleanUp(clean_ups) {
  for (const key in clean_ups) {
    console.log(key);
    if (!clean_ups[key]?.name) {
      return `Please fill Clean Up settings: miss "${key}"`;
    }
  }
  return false;
}

export function countNumberEmployee(empls) {
  let name = [];
  let filteredArrEmpls = [];
  for (let i = 0; i < empls.length; i++) {
    const element = empls[i];

    if (!name.includes(element.name)) {
      filteredArrEmpls.push(element);
      name.push(element.name);
    }
  }

  return filteredArrEmpls;
}

export function getCurentDate() {
  const now = new Date();
  return now.toLocaleDateString("sv-SE");
}

const EXCLUDE_PROPS = ["status", "manager_notes", "out", "notes", "enter_notes"];

export function deepCheckEmptyData(object1, parentObjKey, index, results = []) {
  const objKeys1 = Object.keys(object1);

  for (var key of objKeys1) {
    const value = object1[key];

    if (typeof value === "object" && !Array.isArray(value)) {
      deepCheckEmptyData(value, key, null, results);
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        if (EXCLUDE_PROPS.includes(key)) {
        } else {
          results.push(`Empty list found at "${parentObjKey || ""}" at "${key || ""}"`);
        }
      } else {
        for (let i = 0; i < value.length; i++) {
          if (typeof value[i] === "object") {
            deepCheckEmptyData(value[i], key, i, results);
          } else if (value[i] === "" && typeof value[i] === "string") {
            results.push(
              `Empty item found in "${parentObjKey || ""}" at "${key || ""}" ${index ? `In index "${index}"` : ""}`
            );
          }
        }
      }
    } else if (value === "" && typeof value === "string") {
      if (EXCLUDE_PROPS.includes(key)) {
      } else {
        results.push(
          `Unfilled data found at "${parentObjKey || ""}" "${key || ""}" ${index ? `In index "${index}"` : ""}`
        );
      }
    }
  }

  return results;
}

export function arrErrors_tostring(state) {
  const isCl = deepCheckEmptyData(state);
  if (isCl.length > 0) {
    return isCl;
  }
  return null;
}

export const TEST = () => {
  const textOBJ = {
    bucket_goal: "ff",
    assignedEmployee: { name: "fg", value: "s" },
    assignedBreakeEmployee: { name: "s", value: "23" },
    assignedBreakeTime: "ff", //time After All Potatoes To Fryer
    firstPotatoesToFry: "ff", //time
    lastPotatoesToFry: "ff", //time
    containerType: "ff",
    numberOfContainers: "ff",
    container_weight_with_potatoes: "ff",
    container_weight_with_potatoes_verified: false,
    empty_container_weight: "ff",
    empty_container_weight_verified: false,
    number_of_containers_completed: 0,
    activeEmployee: [{ name: "s", value: "s" }],
    firstPotatoesToFryEmployee: "dfsdf", //time
    lastPotatoesToFryEmployee: "fsd", //time
    clean_ups: {
      buckets: { name: "12", value: "1" },
      peeler: { name: "1", value: "1" },
      urschel: { name: "1", value: "1" },
      floor: { name: "1", value: "1" },
      tools: { name: "1", value: "1" },
    },
    enter_notes: [
      { name: "gg", value: "23" },
      { name: "d", value: "55" },
    ],
    manager_notes: "ff",
  };

  const ff = deepCheckEmptyData(textOBJ);
  console.log(ff);
};

export function CalcParams(state, param) {
  const totalOilWeight = state?.oil_used && state?.oil_used.reduce((acc, v) => acc + +v.value, 0).toFixed(0);

  const RESULT = {
    pounds_of_raw:
      (+state?.container_weight_with_potatoes - +state?.empty_container_weight) *
      +state?.number_of_containers_completed,
    totalMinutes: state?.activeEmployee ? state?.activeEmployee.reduce((acc, v) => acc + +v.minutes, 0).toFixed(0) : "",
    number_of_employees: countNumberEmployee(state?.activeEmployee).length || 0,
    //fryer
    pounds_of_oil: totalOilWeight - state?.oil_used?.length * +state?.bucket_weight_oil,
    pounds_fried: 0,
    //qa
    pre_qa_pounds: state.pre_qa_weight && state.pre_qa_weight.reduce((acc, v) => acc + +v.value, 0).toFixed(1),
    post_qa_pounds: 0,
    number_of_batches_mode: 0,
    // seasoning
    number_of_batches_used: state.flavors && state.flavors.reduce((acc, v) => acc + +v.batches_completed, 0),
    // kitting
    total_bugs_kitted:
      state.flavors && state.flavors.reduce((acc, v) => acc + +v.singles_completed + +v.refills_completed, 0),
    number_of_bugs_left_to_kit:
      state.flavors && state.flavors.reduce((acc, v) => acc + +v.number_of_bags_left_to_kit, 0),
  };

  if (+state?.partial_bucket_weight === 0) {
    RESULT.pounds_fried = (+state?.bucket_weight_with_chips - +state?.empty_bucket_weight) * +state?.buckets_in_fryer;
  }
  if (+state?.partial_bucket_weight > 1) {
    RESULT.pounds_fried =
      (+state?.bucket_weight_with_chips - +state?.empty_bucket_weight) * (+state?.buckets_in_fryer + 1);
  }

  let adds_1 = 0;
  let adds_2 = 0;

  if (+state.startingBucketWeight > 0) {
    adds_1 = +state.bucket_weight_with_chips - +state.startingBucketWeight;
  }
  if (+state.partial_bucket_weight > 0) {
    adds_2 = +state.partial_bucket_weight - +state.empty_bucket_weight;
  }

  RESULT.post_qa_pounds =
    +state.buckets_filled * (+state.bucket_weight_with_chips - +state.empty_bucket_weight) + adds_1 + adds_2;

  RESULT.number_of_batches_mode = ((RESULT.post_qa_pounds * 453.592) / 4000).toFixed(1);

  return RESULT[param];
}

class CommonFunctionality {
  constructor() {
    this.timerSendState = {};
  }

  handleSaveDataFromPopups(type, data, setWarning, setManagerPopup, setState, state) {
    if (type === "activeEmployee") {
      const isCloseStation = data.find(({ status }) => status === "Close Station");
      if (isCloseStation && isCloseStation.out) {
        const isCl = arrErrors_tostring({ ...state, [type]: data });
        if (isCl) {
          setWarning(isCl);
          return;
        }
        setManagerPopup("closeStation");
      }
    }
    setState((pS) => ({ ...pS, [type]: data }));
  }

  onSubmit(stationName, snapShotState, state, setLoading, date, shift) {
    return new Promise((res, rej) => {
      setLoading(true);
      snapShotState.current = state;
      updateStateStation(stationName, state, date, shift)
        .then((r) => {
          console.log(stationName, "updateStateStation", r);
          res(r);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        })
        .finally(() => setLoading(false));
    });
  }

  updateStatePerChange(stationName, snapShotState, state, setLoading, date, shift) {
    if (snapShotState.current) {
      if (!isDeepEqual(snapShotState.current, state)) {
        clearTimeout(this.timerSendState[stationName]);
        this.timerSendState[stationName] = setTimeout(() => {
          this.onSubmit(stationName, snapShotState, state, setLoading, date, shift);
        }, 1500);
      }
    }
  }

  getDataStationEmployee(
    stationName,
    snapShotState,
    setLoading,
    setEmployees,
    setState,
    setStationInfo,
    setManagerPopup
  ) {
    setLoading(true);
    getAPI_per_station(stationName)
      .then((r) => {
        console.log(stationName, "mount", r);
        if (!r) return;
        setEmployees(r.empl);
        setState((pS) => {
          const newState = { ...pS, ...r.station.state };
          snapShotState.current = newState;
          return newState;
        });
        const info = { ...r.station };
        delete info.state;
        setStationInfo(info);
        if (!r.station.start) {
          setManagerPopup("openStation");
        }
      })
      .catch((e) => {
        console.log(stationName, "station ERROR", e);

        if (e.msg === "The shift doesn't exist on this day") {
        }

        if (e.msg === "Should open the station first") {
          getSettings()
            .then((r) => {
              const empl = r.settings.productionEmployee.assignedEmployees.map((e) => ({
                value: e.value,
                name: e.name,
                status: e.status || "",
              }));
              setEmployees(empl);
              setManagerPopup("openStation");
            })
            .catch((e) => console.log(e));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  getDataStationManager(stationName, snapShotManagerState, date, shift, setLoading, setShiftState, setState) {
    return new Promise((res, rej) => {
      setLoading(true);
      getAPI_per_station_manager(stationName, date, shift)
        .then((rr) => {
          console.log("MANAGER", date, stationName, rr);
          setLoading(false);

          // if (!rr) {
          //   setShiftState((pS) => ({
          //     activeShift: 0,
          //     list: [],
          //   }));
          //   setState((pS) => {
          //     let stN = stationName === "fryer" ? "fry" : stationName;

          //     if (stationName === "fry_batch" || stationName === "crate_weight") {
          //       snapShotManagerState.current = pS;
          //       return pS;
          //     }

          //     const titi = MANAGER_INIT[stN];
          //     snapShotManagerState.current = titi;
          //     return titi;
          //   });
          //   rej();
          //   return;
          // }

          setShiftState((pS) => {
            const info = { ...rr.station };
            delete info.state;
            return {
              ...pS,
              list: rr.shifts,
              ...info,
            };
          });
          setState((pS) => {
            let stN = stationName === "fryer" ? "fry" : stationName;

            if (stationName === "fry_batch" || stationName === "crate_weight") {
              const titi = deepModify(pS, rr.station.state);
              snapShotManagerState.current = titi;

              return titi;
            }

            const titi = deepModify(stationName === "kitting" ? pS : MANAGER_INIT[stN], rr.station.state);
            snapShotManagerState.current = titi;
            return titi;
          });
        })
        .catch((e) => {
          setState((pS) => {
            let stN = stationName === "fryer" ? "fry" : stationName;

            if (stationName === "fry_batch" || stationName === "crate_weight") {
              snapShotManagerState.current = pS;
              return pS;
            }

            const titi = MANAGER_INIT[stN];
            snapShotManagerState.current = titi;
            return titi;
          });
          rej(e);
          return;
        });
    });
  }

  createShift(stationName, snapShotManagerState, date, shift, setLoading, setShiftState, setState) {
    setLoading(true);

    let stN = stationName === "fryer" ? "fry" : stationName;
    createStationShift(stationName, date, { state: MANAGER_INIT[stN] })
      .then((r) => {
        console.log(stationName, "MANAGER CREATE NEW SHIFT", r);
        this.getDataStationManager(stationName, snapShotManagerState, date, shift, setLoading, setShiftState, setState);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }

  deleteShift(stationName, shiftId, snapShotManagerState, date, shift, setLoading, setShiftState, setState) {
    setLoading(true);
    deleteStationShift(stationName, shiftId)
      .then((r) => {
        console.log(stationName, "MANAGER DELETE SHIFT", r);
        this.getDataStationManager(stationName, snapShotManagerState, date, shift, setLoading, setShiftState, setState);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }
}
export default new CommonFunctionality();

export function randomHexColor() {
  let [r, g, b] = randomRgbColor();
  let hr = r.toString(16).padStart(2, "0");
  let hg = g.toString(16).padStart(2, "0");
  let hb = b.toString(16).padStart(2, "0");

  function randomRgbColor() {
    let r = randomInteger(255);
    let g = randomInteger(255);
    let b = randomInteger(255);
    return [r, g, b];
  }
  function randomInteger(max) {
    return Math.floor(Math.random() * (max + 1));
  }

  return "#" + hr + hg + hb;
}
export function randomValue(max = 30) {
  return Math.floor(Math.random() * (max + 1));
}

export function getCountData() {
  return new Promise((res, rej) => {
    const { from, to } = getWeekRange();
    // console.log("From:", from);
    // console.log("To:", to);

    const allPromises = [];

    for (let i = 0; i < STATIONS.length; i++) {
      const station = STATIONS[i];
      allPromises.push(
        new Promise((res, rej) => {
          try {
            res(getStateStationDashboard(station, from, to));
          } catch (error) {
            rej(error);
          }
        })
      );
    }

    Promise.all(allPromises)
      .then((r) => {
        // console.log(r);

        let qa_number_of_batches_made_weekly = 0;
        let seasoning_number_of_batches_used_weekly = 0;

        const qaData = r[2].states;
        const seasoningData = r[3].states;

        qa_number_of_batches_made_weekly = qaData.reduce((acc, v) => {
          const state = v.state;
          let adds_1 = 0;
          let adds_2 = 0;
          if (+state.startingBucketWeight > 0) {
            adds_1 = +state.bucket_weight_with_chips - +state.startingBucketWeight;
          }
          if (+state.partial_bucket_weight > 0) {
            adds_2 = +state.partial_bucket_weight - +state.empty_bucket_weight;
          }
          const post_qa_pounds =
            +state.buckets_filled * (+state.bucket_weight_with_chips - +state.empty_bucket_weight) + adds_1 + adds_2;
          const number_of_batches_mode = +((post_qa_pounds * 453.592) / 4000).toFixed(1);
          return acc + +number_of_batches_mode;
        }, 0);

        seasoning_number_of_batches_used_weekly = seasoningData.reduce((acc, v) => {
          const state = v.state;
          const number_of_batches_used = state.flavors.reduce((acc, fl) => acc + +fl.batches_completed, 0);
          return acc + +number_of_batches_used;
        }, 0);

        const batchesToBeSeasoned = qa_number_of_batches_made_weekly - seasoning_number_of_batches_used_weekly;

        res({ batchesToBeSeasoned });
      })
      .catch((e) => {
        res(null);
      });
  });
}

function getWeekRange() {
  const today = new Date();
  const currentDay = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

  // Calculate the difference between the current day and Monday
  const daysToMonday = (currentDay + 6) % 7;

  // Set the date to the Monday of the current week
  const monday = new Date(today);
  monday.setDate(today.getDate() - daysToMonday);

  // Set the date to the Sunday of the current week
  const sunday = new Date(today);
  sunday.setDate(today.getDate() + (6 - daysToMonday));

  return {
    from: monday.toLocaleDateString("sv-SE"),
    to: sunday.toLocaleDateString("sv-SE"),
  };
}

export function isEmptyFlavorValues(data) {
  const types = Object.keys(data);
  let isEmptyQuantity = true;
  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    if (data[type].hasOwnProperty("quantity") && data[type]["quantity"]) {
      isEmptyQuantity = false;
      break;
    }
  }
  return isEmptyQuantity;
}

export function countPeriod(period) {
  const dateNow = new Date();
  const timestamp = dateNow.getTime();

  const to = dateNow.toLocaleDateString("sv-SE");

  let from = to;

  switch (period) {
    case "today":
      from = new Date(timestamp - 86400000).toLocaleDateString("sv-SE");
      break;
    case "week":
      from = new Date(timestamp - 604800000).toLocaleDateString("sv-SE");
      break;
    case "month":
      from = new Date(timestamp - 2592000000).toLocaleDateString("sv-SE");
      break;
    case "year-to-date":
      from = new Date(timestamp - 31536000000).toLocaleDateString("sv-SE");
      break;
    case "quartal-to-date":
      from = new Date(timestamp - 7776000000).toLocaleDateString("sv-SE");
      break;

    default:
      break;
  } 

  return { from, to };
}
