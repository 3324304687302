import React, { useState, useRef, useEffect } from "react";
import { Button, Form, InputGroup, Row, Badge, Dropdown } from "react-bootstrap";

import { COLORS } from "../../../config";
import CommonFunctionality from "../../../utils/utils";
import LoaderFull from "../../../helpers_components/Loader";
import ManagerPopup from "../../../helpers_components/ManagerPopup";
import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import PopupPreQAweight from "../../../helpers_components/PopupsStations/PreQA_weight";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import PopupWarning from "../../../helpers_components/PopupsStations/Warning";
import StationInfo from "../../../helpers_components/stationInfo";

export default function QA() {
  const snapShotState = useRef({});
  const [loading, setLoading] = useState(true);
  const [managerPopup, setManagerPopup] = useState(false);

  const [warning, setWarning] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [state, setState] = useState({});
  const [popup, setPopup] = useState(null);

  const [stationInfo, setStationInfo] = useState({});

  useEffect(() => {
    CommonFunctionality.getDataStationEmployee(
      "qa",
      snapShotState,
      setLoading,
      setEmployees,
      setState,
      setStationInfo,
      setManagerPopup
    );
  }, []);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange("qa", snapShotState, state, setLoading);
  }, [state]);
  const handleChange = (e) => {
    e.preventDefault();
    let attr = e.target.getAttribute("data-handle") || e.target.parentNode.getAttribute("data-handle");
    const value = e.target.value;

    if (attr.includes("verified")) {
      setState((pS) => ({
        ...pS,
        [attr]: !pS[attr],
      }));
      return;
    }

    if (attr === "increase_bucket") {
      setState((pS) => ({ ...pS, buckets_filled: pS.buckets_filled + 1 }));
      return;
    }
    if (attr === "decrease_bucket") {
      setState((pS) => ({ ...pS, buckets_filled: pS.buckets_filled - 1 }));
      return;
    }
    setState((pS) => ({ ...pS, [attr]: value }));
  };
  const closeManagerPopup = () => setManagerPopup(null);
  const closeModal = () => setPopup(null);
  const handleSaveDataFromPopups = (type, data) => {
    CommonFunctionality.handleSaveDataFromPopups(type, data, setWarning, setManagerPopup, setState, state);
  };

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />
      <ManagerPopup
        show={managerPopup}
        closeManagerPopup={closeManagerPopup}
        station="qa"
        stationState={state}
        employeesFullList={employees}
      />
      {!state.isStationActive && !managerPopup && <PopupStationNotActive />}
      {stationInfo.end && <PopupStationNotActive text={"This shift has already closed"} />}
      {warning && <PopupWarning text={warning} closeModal={() => setWarning(null)} />}
      {popup === "active_employee" && (
        <PopupActiveEmployee
          employeesFullList={employees}
          employeesListFromDB={state.activeEmployee}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "clean_up" && (
        <PopupCleanUp
          employeesFullList={employees}
          clean_ups={state.clean_ups}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
          station={"qa"}
        />
      )}
      {popup === "enter_notes" && (
        <PopupEnterNotes
          employeesFullList={employees}
          enter_notes={state.enter_notes}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "pre_qa_weight" && (
        <PopupPreQAweight
          container_weight={state.container_weight}
          container_weight_verified={state?.container_weight_verified}
          pre_qa_weight={state.pre_qa_weight}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
          handleChangeCustom={handleChange}
        />
      )}

      <Dropdown className="drop_down_popups_list">
        <Dropdown.Toggle id="dropdown-basic">Settings</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setPopup("active_employee")}>Active Employee</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("pre_qa_weight")}>Pre QA Weight</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("clean_up")}>Clean Up</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("enter_notes")}>Enter Notes</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <StationInfo stationInfo={stationInfo} />

      <div className="fs_c_40 text-center">
        QA Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <Row className="text-center mb-3">
        <div className="fs_c_30 mb-2">
          Assigned Employee Start: <span className="label_color">{state.assignedEmployeesStart?.name}</span> (
          <span className="label_color">{state.assignedEmployeesStartTime}</span>)
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Employee After Float: <span className="label_color">{state.assignedEmployeesAfterFloat?.name}</span>{" "}
          (<span className="label_color">{state.assignedEmployeesAfterFloaTime}</span>)
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Employee After Raw Clean Up:{" "}
          <span className="label_color">{state.assignedEmployeesAfterRawCleanUp?.name}</span> (
          <span className="label_color">{state.assignedEmployeesAfterRawCleanUpTime}</span>)
        </div>
        <div className="fs_c_30">
          Assigned Employee After Fry Clean Up :{" "}
          <span className="label_color">{state.assignedEmployeesAfterFryerCleanUp?.name}</span> (
          <span className="label_color">{state.assignedEmployeesAfterFryerCleanUpTime}</span>)
        </div>
      </Row>

      <Row>
        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Starting Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            disabled
            type="number"
            style={{ color: COLORS.LabelData, textAlign: "end" }}
            onChange={handleChange}
            value={state.startingBucketWeight}
          />
        </InputGroup>

        <div className="fs_c_30 mb-3 text-center">Buckets Filled</div>
        <h5 className="mb-3 text-center">do not include starting or ending bucket if they are parital </h5>
        <div className="d-flex flex-row align-items-center mb-3 justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className="mr-2 fs_c_100"
            style={{ marginRight: 30 }}
            data-handle="increase_bucket"
            onClick={handleChange}
          >
            +
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="fs_c_100"
            onClick={handleChange}
            data-handle="decrease_bucket"
            style={{ paddingRight: 30, paddingLeft: 30 }}
          >
            -
          </Button>
          <Badge bg="#EE476F" className="fs_c_100">
            {state.buckets_filled}
          </Badge>
        </div>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Partial (Final) Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle="partial_bucket_weight"
            onChange={handleChange}
            value={state.partial_bucket_weight}
          />
        </InputGroup>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Bucket Weight with Chips:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="bucket_weight_with_chips"
            disabled
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.bucket_weight_with_chips}
            onChange={handleChange}
          />
          <Button
            onClick={handleChange}
            className="button_verify"
            data-handle="bucket_weight_with_chips_verified"
            variant={!state.bucket_weight_with_chips_verified ? "danger" : "light"}
          >
            {!state.bucket_weight_with_chips_verified ? "Verify" : "Verified"}
          </Button>
        </InputGroup>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Empty Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="empty_bucket_weight"
            disabled
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.empty_bucket_weight}
            onChange={handleChange}
          />
          <Button
            onClick={handleChange}
            className="button_verify"
            data-handle="empty_bucket_weight_verified"
            variant={!state.empty_bucket_weight_verified ? "danger" : "light"}
          >
            {!state.empty_bucket_weight_verified ? "Verify" : "Verified"}
          </Button>
        </InputGroup>
      </Row>
    </div>
  );
}
