import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Table, Image } from "react-bootstrap";

import { randomValue, getSourceImage } from "../../../utils/utils";
import { getDashboardOrders } from "../../../utils/api";
import LoaderFull from "../../../helpers_components/Loader";

const columns = ["Priority", "Source", "Order #", "Type", "Shipping Type", "Aged", "Notes"];

export default function Orders() {
  let params = useParams();
  const interval = useRef();
  // console.log("params", params);

  const [state, setState] = useState({ list: [], isLoading: false });

  useEffect(() => {
    getData();
    interval.current = setInterval(getData, 10000);
    return () => {
      clearInterval(interval.current);
    };
  }, [params.page]);

  const getData = () => {
    const page = params.page;
    setState((pS) => ({ ...pS, isLoading: true }));
    getDashboardOrders(page)
      .then((r) => {
        setState((pS) => ({
          ...pS,
          list: r || [],
          isLoading: false,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Row className="dashboard_monitor_container">
        <LoaderFull show={state.isLoading} stylesCustom={{ backgroundColor: "#ffffff00" }} />
        <Table striped bordered>
          <thead>
            <tr className="bg-opacity-50 bg-secondary">
              {columns.map((th) => (
                <th key={th} className="orders_head_item">
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.list.map((st) => (
              <tr key={st.order_name} className="row_cc limited" style={st["priority"] == '1' ? { background: 'rgba(255,96,96,0.5)' } : {}}>
                <td className="order_item " style={{ width: "5%" }}>
                  <small>{st["priority"] == '1' ? 'Priorty' : ''}</small>
                </td>
                <td className="order_item " style={{ width: "5%" }}>
                  <Image src={getSourceImage(st.source)} width={20} />
                </td>
                <td className="order_item " style={{ width: "10%" }}>
                  {st["order_name"]}
                </td>
                <td className="order_item " style={{ width: "5%" }}>
                  {st["order_type"]}
                </td>
                <td className="order_item notes" style={{ width: "15%" }}>
                  {st["shipping_type"]}
                </td>
                <td className="order_item" style={{ width: "5%" }}>
                  {st["aged"]}
                </td>
                <td className="order_item notes" style={{ width: "50%" }}>
                  <small>{st["notes"]}</small>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
}
