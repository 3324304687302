import React, { useState, useEffect } from "react";
import { InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { COLORS } from "../../config";

export default function PopupLoss({ asBlock, loss_data, closeModal, handleSaveDataFromPopups }) {
  const [loss, setLoss] = useState(loss_data || {});

  useEffect(() => {
    setLoss(loss_data);
  }, [loss_data]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2");
    let value = target.value;

    console.log(attr1, attr2, value);

    setLoss((pS) => ({ ...pS, [attr1]: value }));
  };

  const saveChanges = () => {
    handleSaveDataFromPopups("loss", loss);
  };

  return (
    <div className={asBlock ? "" : "popup_container"}>
      <div className={`block ${asBlock ? "daily" : ""}`}>
        {!asBlock && (
          <div className="close_button" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
          </div>
        )}
        <div className="fs_c_30 text-center">Loss</div>

        <InputGroup className="mb-3 ">
          <InputGroup.Text className="fs_c_20">Bags with bad seals:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.LabelData }}
            onChange={handleChange}
            data-handle1="bags_with_bad_seals"
            value={loss.bags_with_bad_seals}
          />
        </InputGroup>
        <InputGroup className="mb-3 ">
          <InputGroup.Text className="fs_c_20">Bad Tubes:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.LabelData }}
            onChange={handleChange}
            data-handle1="bad_tubes"
            value={loss.bad_tubes}
          />
        </InputGroup>
        <InputGroup className="mb-3 ">
          <InputGroup.Text className="fs_c_20">Dented Lids:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.LabelData }}
            onChange={handleChange}
            data-handle1="dented_lids"
            value={loss.dented_lids}
          />
        </InputGroup>
        <InputGroup className="mb-3 ">
          <InputGroup.Text className="fs_c_20">Missing Tubes:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.LabelData }}
            onChange={handleChange}
            data-handle1="missing_tubes"
            value={loss.missing_tubes}
          />
        </InputGroup>

        {/* {!asBlock && ( */}
          <Button
            size="lg"
            className={asBlock ? "" : `save_button mt-3`}
            style={{ padding: "10px 20px" }}
            onClick={saveChanges}
          >
            Save Loss
          </Button>
        {/* )} */}
      </div>
    </div>
  );
}
