import React, { useEffect, useState, useRef } from "react";
import { Row, InputGroup, Button, Form } from "react-bootstrap";
import { COLORS, MANAGER_INIT } from "../../../config";
import CommonFunctionality from "../../../utils/utils";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

export default function QA({ employees, date }) {
  const snapShotManagerState = useRef(MANAGER_INIT.qa);
  const [state, setState] = useState(MANAGER_INIT.qa);

  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "qa",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "qa",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    const attr2 = target.getAttribute("data-handle2");

    let value = target.value;
    if (value === "on") {
      value = target.checked;
    }

    if (
      attr1 === "assignedEmployeesStart" ||
      attr1 === "assignedEmployeesAfterFloat" ||
      attr1 === "assignedEmployeesAfterRawCleanUp" ||
      attr1 === "assignedEmployeesAfterFryerCleanUp"
    ) {
      const newEmployee = employees.find((em) => +em.value === +value);
      value = newEmployee;
    }

    setState((pS) => ({
      ...pS,
      [attr1]: attr2 === "clear" ? "" : value,
    }));
  };

  const createShift = () => {
    CommonFunctionality.createShift(
      "qa",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };
  const deleteShift = (shiftId) => {
    CommonFunctionality.deleteShift(
      "qa",
      shiftId,
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };

  return (
    <Row className="position-relative">
      <LoaderFull show={loading} />

      <ShiftManage
        createShift={createShift}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={deleteShift}
      />
      <Row className="position-relative">
        {!state.isStationActive && <PopupStationNotActive isManagerPage={true} />}
        {shiftState.end && <PopupStationNotActive isManagerPage={true} text={"Shift has already Closed"} />}

        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Employee Start:</InputGroup.Text>
          <Form.Select
            onChange={handleChange}
            data-handle1="assignedEmployeesStart"
            className="fs_c_20"
            style={{ color: COLORS.Dropdown }}
            value={state.assignedEmployeesStart?.value}
          >
             <option className="optionPlaceholder" >Select Employee</option>
            {employees.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
          <InputGroup.Text className="fs_c_20">Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="assignedEmployeesStartTime"
            onChange={handleChange}
            value={state.assignedEmployeesStartTime}
          />
          <Button
            data-handle1="assignedEmployeesStartTime"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Employee After Float:</InputGroup.Text>
          <Form.Select
            onChange={handleChange}
            data-handle1="assignedEmployeesAfterFloat"
            className="fs_c_20"
            style={{ color: COLORS.Dropdown }}
            value={state.assignedEmployeesAfterFloat?.value}
          >
             <option className="optionPlaceholder" >Select Employee</option>
            {employees.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
          <InputGroup.Text className="fs_c_20">Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="assignedEmployeesAfterFloaTime"
            onChange={handleChange}
            value={state.assignedEmployeesAfterFloaTime}
          />
          <Button
            data-handle1="assignedEmployeesAfterFloaTime"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Employee After Raw Clean Up:</InputGroup.Text>
          <Form.Select
            onChange={handleChange}
            data-handle1="assignedEmployeesAfterRawCleanUp"
            className="fs_c_20"
            style={{ color: COLORS.Dropdown }}
            value={state.assignedEmployeesAfterRawCleanUp?.value}
          >
             <option className="optionPlaceholder" >Select Employee</option>
            {employees.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
          <InputGroup.Text className="fs_c_20">Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="assignedEmployeesAfterRawCleanUpTime"
            onChange={handleChange}
            value={state.assignedEmployeesAfterRawCleanUpTime}
          />
          <Button
            data-handle1="assignedEmployeesAfterRawCleanUpTime"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Employee After Fry Clean Up:</InputGroup.Text>
          <Form.Select
            onChange={handleChange}
            data-handle1="assignedEmployeesAfterFryerCleanUp"
            className="fs_c_20"
            style={{ color: COLORS.Dropdown }}
            value={state.assignedEmployeesAfterFryerCleanUp?.value}
          >
             <option className="optionPlaceholder" >Select Employee</option>
            {employees.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
          <InputGroup.Text className="fs_c_20">Time:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="assignedEmployeesAfterFryerCleanUpTime"
            onChange={handleChange}
            value={state.assignedEmployeesAfterFryerCleanUpTime}
          />
          <Button
            data-handle1="assignedEmployeesAfterFryerCleanUpTime"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Starting Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="startingBucketWeight"
            onChange={handleChange}
            value={state.startingBucketWeight}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Bucket Weight with Chips:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="bucket_weight_with_chips"
            onChange={handleChange}
            value={state.bucket_weight_with_chips}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Empty Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="empty_bucket_weight"
            onChange={handleChange}
            value={state.empty_bucket_weight}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Container Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="container_weight"
            onChange={handleChange}
            value={state.container_weight}
          />
        </InputGroup>
      </Row>
    </Row>
  );
}
