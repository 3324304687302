import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, ListGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { STATUSES, detailsStatus } from "../../utils/utils";

import { getLogs } from "../../utils/api";
import LoaderFull from "../../helpers_components/Loader";
import { routes } from "../App/routes";

const mainStatuses = [
  "All systems are operational",
  "System Errors Detected: Maintenance Underway",
  "System Outage: Revert to Manual Tracking",
];

const tsp_arr = Object.keys(STATUSES);

const INIT = {
  mainStatus: mainStatuses[0],
  pagesListStatuses: [],
};

const ACTIONS = {
  "01_qb_connection": "1. Connection to QuickBooks for export",
  "02_from_qb_to_csv": "2. Exporting from QuickBooks to file",
  "03_from_csv_to_s3": "3. Sending exported file to storage",
  "04_qbdb_finished": "4. Finished QuickBooks to DB",
  "05_from_s3_to_db": "5. The inventory imported to the DB",
  "06_from_db_to_s3": "6. The inventory exported from the DB",
  "07_qb_connection": "7. Connection to QuickBooks for import",
  "08_from_s3_to_csv": "8. Retrieving file from storage",
  "09_from_csv_to_qb": "9. Importing from file to QuickBooks",
  "10_dbqb_finished": "10. Finished Full Process",
};

export default function SystemStatus(props) {
  const snapShotState = useRef(INIT);
  const timerSendState = useRef();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    mainStatus: mainStatuses[0],
    pagesListStatuses: routes.map((r) => {
      return { title: r.title, status: "missed", description: "" };
    }),
    schedule_server_log: [],
  });

  useEffect(() => {
    setLoading(true);

    getLogs()
      .then((r) => {
        console.log("GET LOGS", r);
        const dateNow = new Date();

        const day = dateNow.getUTCDate();
        const m = dateNow.getUTCMonth() + 1;
        const year = dateNow.getUTCFullYear();
        let today = `${year}-${m.toString().padStart(2, "0")}-${day}`;

        const sceduledPerDay = {};
        let currentDay = today;
        for (let i = 0; i < r.list.length; i++) {
          const daySc = r.list[i];

          if (daySc.when && daySc.when.includes(currentDay)) {
            if (sceduledPerDay.hasOwnProperty(currentDay)) {
              sceduledPerDay[currentDay].push(daySc);
            } else {
              sceduledPerDay[currentDay] = [];
              sceduledPerDay[currentDay].push(daySc);
            }
          } else {
            currentDay = daySc.when ? daySc.when.slice(0, 10) : daySc.day;
            if (sceduledPerDay.hasOwnProperty(currentDay)) {
              sceduledPerDay[currentDay].push(daySc);
            } else {
              sceduledPerDay[currentDay] = [];
              sceduledPerDay[currentDay].push(daySc);
            }
          }
        }

        // console.log(sceduledPerDay);

        setState({
          ...state,
          schedule_server_log: sceduledPerDay,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sc_per_arr = Object.keys(state.schedule_server_log);

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />

      <Row style={{ alignItems: "center" }} className="my-4">
        <Col className="fs_c_40 text-center">{state.mainStatus}</Col>
      </Row>

      <ul className="d-flex flex-row flex-wrap justify-content-center">
        {tsp_arr.map((status) => (
          <div key={status} className="d-flex align-items-center ms-4">
            <FontAwesomeIcon
              icon={STATUSES[status].icon}
              style={{ width: 20, height: 20, color: STATUSES[status].color }}
            />
            <p className="m-0 ms-2">{status}</p>
          </div>
        ))}
      </ul>

      <Card className="mb-3">
        <Card.Body>
          {/* <Card.Title className="text-center">Statuses Work with QB</Card.Title> */}
          <ListGroup className="list-group-flush">
            <ListGroup.Item className="d-flex align-items-center justify-content-between">
              <Col xs={2} className="text-center">
                <p className="m-0 fs_c_20">Day</p>
              </Col>
              <Col xs={2} className="text-center">
                <p className="m-0 fs_c_20">Time</p>
              </Col>
              <Col xs={3} className="text-center">
                <p className="m-0 fs_c_20">Action / Description</p>
              </Col>
              <Col xs={5} className="text-center">
                <p className="m-0 fs_c_20">Status / Note</p>
              </Col>
            </ListGroup.Item>
            {sc_per_arr.map((d) => (
              <ListGroup.Item key={d} className="d-flex align-items-center justify-content-between">
                <Col xs={2} className="text-center">
                  <p className="m-0">{d}</p>
                </Col>
                <Col xs={2} className="text-center">
                  <ListGroup className="list-group-flush">
                    {state.schedule_server_log[d] &&
                      state.schedule_server_log[d].length &&
                      Object.keys(ACTIONS).map((ACTION) => (
                        <ListGroup.Item className="" key={`time_${d}_${ACTION}`}>
                          {state.schedule_server_log[d].find((act) => act.action === ACTION)
                            ? (state.schedule_server_log[d].find((act) => act.action === ACTION).when || "").slice(
                                11
                              ) || "-"
                            : "-"}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Col>
                <Col xs={4} className="">
                  <ListGroup className="list-group-flush">
                    {state.schedule_server_log[d] &&
                      state.schedule_server_log[d].length &&
                      Object.keys(ACTIONS).map((ACTION) => (
                        <ListGroup.Item className="" key={`action_${d}_${ACTION}`}>
                          <small>
                            {state.schedule_server_log[d].find((act) => act.action === ACTION)
                              ? ACTIONS[state.schedule_server_log[d].find((act) => act.action === ACTION).action]
                              : ACTIONS[ACTION]}
                          </small>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Col>
                <Col xs={4} className="text-center">
                  <ListGroup className="list-group-flush">
                    {state.schedule_server_log[d] &&
                      state.schedule_server_log[d].length &&
                      Object.keys(ACTIONS).map((ACTION) => (
                        <ListGroup.Item className="" key={`details_${d}_${ACTION}`}>
                          {state.schedule_server_log[d].find((act) => act.action === ACTION)
                            ? detailsStatus(state.schedule_server_log[d].find((act) => act.action === ACTION).status)
                            : detailsStatus("missed")}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Col>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroup.Item className="d-flex align-items-center justify-content-between">
              <Col xs={4} className="text-center">
                <p className="m-0 fs_c_20">Page</p>
              </Col>
              <Col xs={3} className="text-center">
                <p className="m-0 fs_c_20">Status</p>
              </Col>
              <Col xs={5} className="text-center">
                <p className="m-0 fs_c_20">Description</p>
              </Col>
            </ListGroup.Item>
            {state.pagesListStatuses.map((page) => (
              <ListGroup.Item key={page.title} className="d-flex align-items-center justify-content-between">
                <Col xs={4}>
                  <p className="m-0">{page.title}</p>
                </Col>
                <Col xs={3}>
                  <div className="d-flex align-items-center ms-4">
                    <FontAwesomeIcon
                      icon={STATUSES[page.status].icon}
                      style={{ width: 20, height: 20, color: STATUSES[page.status].color }}
                    />
                    <p className="m-0 ms-2">{page.status}</p>
                  </div>
                </Col>
                <Col xs={5}>
                  <p className="m-0">{page.description}</p>
                </Col>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  );
}
