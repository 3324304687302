import React, { useState } from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../../../helpers_components/ErrorFeedback";

import { COLORS, yes_no_options, crateNumbers, potato_conditions_checkbox } from "../../../config";

export default function SectionOne({ onNextSection, state, employees }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      batch_Id: state.batch_Id || "",
      number_of_chippers: state.number_of_chippers || "",
      notes: state.notes || "",
      your_initials: state.your_initials || "",
    },
    validationSchema: Yup.object({
      batch_Id: Yup.string().required("This is a required field"),
      number_of_chippers: Yup.string().required("This is a required field"),
      your_initials: Yup.string().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SectionOne_Crate", values);
      onNextSection("section_1", values);
    },
  });

  const submit = (event) => {
    event.preventDefault();

    val
      .validateForm()
      .then((r) => {
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }
        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    val.resetForm();
  };

  return (
    <Form onSubmit={submit}>
      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Batch Id <span>*</span>
          </h2>

          <Form.Control
            type="number"
            name="batch_Id"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.batch_Id || ""}
            isInvalid={val.errors.batch_Id ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.batch_Id} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Number of Chippers <span>*</span>
          </h2>

          <Form.Control
            type="number"
            name="number_of_chippers"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.number_of_chippers || ""}
            isInvalid={val.errors.number_of_chippers ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.number_of_chippers} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">Notes</h2>
          <Form.Control
            type="text"
            name="notes"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.notes || ""}
            isInvalid={val.errors.notes ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.notes} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Your Initials <span>*</span>
          </h2>
          <Form.Select
            className="fs_c_20 "
            style={{ color: COLORS.Dropdown }}
            name="your_initials"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.your_initials || ""}
            isInvalid={val.errors.your_initials ? true : false}
          >
            <option value={""}>Choose</option>
            {employees.map((em) => (
              <option key={em.id} value={em.id}>
                {em.initials}
              </option>
            ))}
          </Form.Select>
          <ErrorFeedback error={val.errors.your_initials} />
        </Card.Body>
      </Card>

      <Row>
        <Col md="1">
        <Button
            type="submit"
            size="sm"
            className="fs_c_20 "         
            variant="primary"
          >
            Submit
          </Button>
        </Col>
        <Col md="8"></Col>
        <Col md="3">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 "
            style={{ padding: "5px 20px", background: "none", color: "rgb(0, 144, 176)", border: "none" }}
            variant="light"
            onClick={clearForm}
          >
            Clear Form
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
