import React, { useEffect, useState, useRef } from "react";
import { Row, InputGroup, Button, Form } from "react-bootstrap";

import { COLORS, MANAGER_INIT } from "../../../config";

import CommonFunctionality from "../../../utils/utils";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

export default function Raw({ employees, date }) {
  const snapShotManagerState = useRef(MANAGER_INIT.raw);
  const [state, setState] = useState(MANAGER_INIT.raw);

  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "raw",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "raw",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1") || target.parentNode.getAttribute("data-handle1");
    const attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");
    let value = target.value;

    if (value === "on") {
      value = target.checked;
    }

    // console.log(attr1, value);

    setState((pS) => {
      if (attr1 === "assignedEmployee" || attr1 === "assignedBreakeEmployee") {
        const newEmployee = employees.find((em) => +em.value === +value);
        value = newEmployee;
      }
      if (attr1 === "isStationActive") value = !pS[attr1];
      if (attr2 === "clear") value = "";

      return {
        ...pS,
        [attr1]: value,
      };
    });
  };

  const createShift = () => {
    CommonFunctionality.createShift(
      "raw",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };
  const deleteShift = (shiftId) => {
    CommonFunctionality.deleteShift(
      "raw",
      shiftId,
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };

  return (
    <Row className="position-relative">
      <LoaderFull show={loading} />

      <ShiftManage
        createShift={createShift}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={deleteShift}
      />

      <div className="position-relative">
        {!state.isStationActive && <PopupStationNotActive isManagerPage={true} />}
        {shiftState.end && <PopupStationNotActive isManagerPage={true} text={"Shift has already Closed"} />}

        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Goal (lbs):</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
            data-handle1="bucket_goal"
            onChange={handleChange}
            value={state.bucket_goal}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Employee:</InputGroup.Text>
          <Form.Select
            onChange={handleChange}
            data-handle1="assignedEmployee"
            className="fs_c_20 text-end"
            style={{ color: COLORS.Dropdown }}
            value={state.assignedEmployee.value}
          >
             <option className="optionPlaceholder" >Select Employee</option>
            {employees.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Break Employee:</InputGroup.Text>
          <Form.Select
            onChange={handleChange}
            data-handle1="assignedBreakeEmployee"
            className="fs_c_20 text-end"
            style={{ color: COLORS.Dropdown }}
            value={state.assignedBreakeEmployee.value}
          >
             <option className="optionPlaceholder" >Select Employee</option>
            {employees.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Assigned Break Time (Raw):</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="assignedBreakeTime"
            onChange={handleChange}
            value={state.assignedBreakeTime}
          />
          <Button
            data-handle1="assignedBreakeTime"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">First Potatoes To Fry Station (Goal Time):</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="firstPotatoesToFry"
            onChange={handleChange}
            value={state.firstPotatoesToFry}
          />
          <Button
            data-handle1="firstPotatoesToFry"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Last Potatoes To Fry Station (Goal Time):</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle1="lastPotatoesToFry"
            onChange={handleChange}
            value={state.lastPotatoesToFry}
          />
          <Button
            data-handle1="lastPotatoesToFry"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Container Type:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="text"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="containerType"
            onChange={handleChange}
            value={state.containerType}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Number of Containers:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="numberOfContainers"
            onChange={handleChange}
            value={state.numberOfContainers}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Container Weight With Potatoes:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="container_weight_with_potatoes"
            onChange={handleChange}
            value={state.container_weight_with_potatoes}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text className="fs_c_20">Empty Container Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30 text-end"
            type="number"
            style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
            data-handle1="empty_container_weight"
            onChange={handleChange}
            value={state.empty_container_weight}
          />
        </InputGroup>
      </div>
    </Row>
  );
}
