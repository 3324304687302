import { getCurentDate } from "../utils/utils";

export default function StationInfo({ stationInfo }) {
  return (
    <div className="date_now_station">
      <p>{getCurentDate()}</p>
      <p>Shift: {stationInfo.shift || "morning"}</p>
      {stationInfo.start && <p>Started: {new Date(stationInfo.start).toLocaleTimeString()}</p>}
      {stationInfo.end && <p>Closed: {new Date(stationInfo.end).toLocaleTimeString()}</p>}
    </div>
  );
}
