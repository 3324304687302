import React, { useState, useEffect, useRef } from "react";
import { Row, InputGroup, Form, Tabs, Tab } from "react-bootstrap";

import { COLORS, MANAGER_INIT } from "../../../config";
import CommonFunctionality, { CalcParams } from "../../../utils/utils";
import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import TabDataKittingDailyLog from "./TabDataKitting";
import PopupLoss from "../../../helpers_components/PopupsStations/Loss";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

export default function KittingDaily({ date, employees }) {
  const snapShotManagerState = useRef(MANAGER_INIT.kitting);

  const [action, setAction] = useState("Flavor: 1");
  const [state, setState] = useState(MANAGER_INIT.kitting);
  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "kitting",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "kitting",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1") || target.parentNode.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");
    let attr3 = target.getAttribute("data-handle3") || target.parentNode.getAttribute("data-handle3");
    let attr4 = target.getAttribute("data-handle4") || target.parentNode.getAttribute("data-handle4");

    let value = target.value;

    console.log(attr1, attr3, attr4, value);

    if (attr1 === "flavors") {
      value = state.flavors.map((fl) => {
        if (fl.id === attr4) {
          if (value === "true" || value === "false") {
            value = JSON.parse(value);
          }

          return { ...fl, [attr3]: attr2 === "clear" ? "" : value };
        }
        return fl;
      });
    }

    setState((pS) => ({
      ...pS,
      [attr1]: value,
    }));
  };

  const handleSaveDataFromPopups = (type, data) => {
    console.log(type, data);
    setState((pS) => ({ ...pS, [type]: data }));
  };

  return (
    <>
      <div className="fs_c_40 text-center">
        Kitting Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <LoaderFull show={loading} />

      <ShiftManage
        createShift={null}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={() => {}}
      />
      {shiftState.list.length > 0 && (
        <>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Total Bags Kitted:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "total_bugs_kitted")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Bags Left To Kit:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "number_of_bugs_left_to_kit")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Total Minutes:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "totalMinutes")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Employees:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "number_of_employees")}
              />
            </InputGroup>
          </Row>

          <h3 className="fs_c_30 text-center">Process</h3>

          <Row className="">
            <Tabs
              defaultActiveKey={action}
              id="uncontrolled-tab-example"
              onSelect={(k) => setAction(k)}
              activeKey={action}
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              {state.flavors.map((tab, ind) => (
                <Tab key={tab.id + ind} eventKey={tab.name} title={tab.name || ""} className="tab_container">
                  <TabDataKittingDailyLog state={tab} handleChangeFlavor={handleChange} />
                </Tab>
              ))}
            </Tabs>
          </Row>

          <PopupActiveEmployee
            asBlock={true}
            employeesFullList={employees}
            employeesListFromDB={state?.activeEmployee}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />

          <PopupLoss asBlock={true} loss_data={state?.loss} handleSaveDataFromPopups={handleSaveDataFromPopups} />

          <PopupEnterNotes
            asBlock={true}
            employeesFullList={employees}
            enter_notes={state?.enter_notes}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />
          <Row>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Manager Notes for Kitting:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="manager_notes"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.manager_notes || ""}
                onChange={handleChange}
              />
            </InputGroup>
          </Row>
        </>
      )}
    </>
  );
}
