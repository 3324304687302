import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Row, Dropdown, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const ErrorFeedback = ({ error, styleCustom }) => {
  return (
    <Form.Control.Feedback type="invalid" style={styleCustom ? styleCustom : {}}>
      <FontAwesomeIcon icon={icon({ name: "circle-exclamation", style: "solid" })} />
      {error}
    </Form.Control.Feedback>
  );
};
export default ErrorFeedback;
