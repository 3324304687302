import React, { useEffect, useState } from "react";

import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

import { COLORS } from "../../config";
import Fryer from "./components/fryer";
import Raw from "./components/raw";
import QA from "./components/qa";
import Seasoning from "./components/seasoning";

import { getPeriodDefault } from "../../utils/utils";

export default function OperationManagerDashboard(props) {
  const [state, setState] = useState(() => {
    const period = getPeriodDefault(new Date());
    return { from: period.from, to: period.to };
  });

  const handleChange = (e) => {
    e.preventDefault();
    let customValue = e.target.getAttribute("data-handle");
    if (!customValue) {
      customValue = e.target.parentNode.getAttribute("data-handle");
    }
    const value = e.target.value;
    console.log(customValue, value);

    setState((pS) => ({ ...pS, [customValue]: value }));
  };

  return (
    <div className="">
      <Row>
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_30">From:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="date"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              data-handle="from"
              onChange={handleChange}
              value={state.from}
            />
          </InputGroup>
        </Col>
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_30">To:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="date"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              data-handle="to"
              onChange={handleChange}
              value={state.to}
            />
          </InputGroup>
        </Col>
      </Row>

      <Fryer from={state.from} to={state.to} />
      <Raw from={state.from} to={state.to} />
      <QA from={state.from} to={state.to} />
      <Seasoning from={state.from} to={state.to} />     
    </div>
  );
}
