import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import SectionOne from "./SectionOne";

import PopupSignInOut from "../../../helpers_components/PopupsStations/SignInOut";
import Header from "../../../helpers_components/Header";

import { Station } from "../../../components/Station";

export default class SeasoningInformation extends Station {
  constructor(props) {
    super(props, "seasoning_information", "Seasoning Information", { section_1: {} });
  }

  render() {
    return (
      <div className="position-relative mb-6">
        {this.state.popup === "sign_in_out" && (
          <PopupSignInOut closeModal={this.toggleModal} employees={this.state.employees} />
        )}

        <Header title="Seasoning Information" toggleModal={this.toggleModal} />

        <Tabs activeKey={this.state.key} className="mb-3 hideblock">
          <Tab eventKey="section_1" title="section_1">
            <SectionOne
              onNextSection={this.onNextSection}
              state={this.state.data.section_1}
              employees={this.state.employees}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
