import Inventory from "../Inventory";
import EmployeCheck from "../EmployeeCheck";
import Raw from "../Stations/Raw";
import CrateWeight from "../Stations/CrateWeight";
import Fryer from "../Stations/Fryer";
import QA from "../Stations/QA";
import Seasoning from "../Stations/Seasoning";
import Kitting from "../Stations/Kitting";

import OperationManagerDashboard from "../Operation_manager_dushboard";

import ForgotPassword from "../Forgot_password";
import Login from "../Login/Login";
import ManagerPage from "../Manager_Page";
import SystemStatus from "../SystemStatus";
import DailyLog from "../DailyLog";
import Dashboard from "../Dashboard";
import Orders from "../Dashboard/Screens/Orders";
import FryerBatch from "../Stations/FryerBatch";
import SeasoningInformation from "../Stations/SeasoningInformation";



export const routes = [
  { href: "operation_manager", title: "Operation Manager Dashboard", component: <OperationManagerDashboard /> },
  { href: "managerpage", title: "Manager Page", component: <ManagerPage /> },
  { href: "daily-log", title: "Daily Log", component: <DailyLog /> },
  
  // { href: "inventory", title: "Inventory", component: <Inventory /> },
  { href: "inventory-receiving", title: "Inventory Receiving", component: <Inventory /> },
  { href: "inventory-builds", title: "Inventory Builds", component: <Inventory /> },

  { href: "employee_checking", title: "Employees", component: <EmployeCheck /> },

  { href: "raw", title: "Raw Station", component: <Raw /> },
  { href: "crate-weight", title: "New Raw (Crate Weight)", component: <CrateWeight /> }, // new Raw screen

  { href: "fryer", title: "Fryer Station", component: <Fryer /> },
  { href: "fryer-batch", title: "New Fryer (Fryer Batch)", component: <FryerBatch /> }, // new Fryer screen

  { href: "qa", title: "QA Station", component: <QA /> },
  { href: "seasoning", title: "Seasoning Station", component: <Seasoning /> },
  { href: "seasoning-information", title: "Seasoning Information", component: <SeasoningInformation /> },


  { href: "kitting", title: "Kitting Station", component: <Kitting /> },
  { href: "system-status", title: "System Status", component: <SystemStatus /> },  
  { href: "dashboard-estate-inventory", title: "Dashboard Estate Flavors", component: <Dashboard /> },
  { href: "dashboard-limted-edition-inventory", title: " Dashboard Limited Edition Flavors", component: <Dashboard /> },
  { href: "fulfillment-priority/:page", title: "Dashboard Orders 1", component: <Orders /> },
  { href: "fulfillment-priority/:page", title: "Dashboard Orders 2", component: <Orders /> },

];

export const publicRoutes = [
  { href: "forgot-password/:code", title: "Forgot Password", component: <ForgotPassword /> },
  { href: "forgot-password", title: "Forgot Password", component: <ForgotPassword /> },
  { href: "/", title: "Login", component: <Login /> },
  { href: "login", title: "Login", component: <Login /> },
];
