import React, { useState } from "react";

import { Alert, Button, Col, Form, Row, Navbar, Container, CardBody, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBurger, faLock } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import useToken from "../App/useToken";
import useUser from "../App/useUser";

import { loginUser } from "../../utils/api";

const permissions = [{ name: "admin", value: "admin" }];

export default function Login() {
  const { setToken } = useToken();
  const { setUser } = useUser();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError();
    loginUser({
      login,
      password,
    }).then((r) => {
      if (!r.auth) {
        console.log(r);
        setError(r);
      }
      setUser(r.user || null);
      setToken(r.auth || null);
      window.location.reload();
    });
  };

  return (
    <div className="pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          {error && (
            <Alert variant="danger" className="row">
              {`${
                error.error && (error.error.msg || "") + " " + (error.error.code ? "(" + error.error.code + ")" : "")
              }`}
            </Alert>
          )}

          <Col lg={12} className="mb-6 mt-3">
            <div className="text-center mb-1 text-muted">
              <Link to="#" className="d-block auth-logo">
                <img alt="logo-wide" src="/logo-wide.png" width="70%" id="logoImg" className="mx-auto py-2 my-2" />
              </Link>
            </div>
          </Col>

          <Col md={8} lg={6} xl={5} style={{ boxShadow: "0px 0px 12px -4px rgba(0,0,0,0.75)", padding: "40px 40px" }}>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3 row">
                {/* <Form.Label>Login *</Form.Label> */}
                <Form.Control
                  type="text"
                  name="login"
                  placeholder="Login *"
                  onChange={(e) => setLogin(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 row">
                {/* <Form.Label>Password *</Form.Label> */}
                <Form.Control
                  type="password"
                  placeholder="Password *"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3 pb-3 row">
              <Form.Label>Choose role</Form.Label>
              <Form.Select
                // onChange={handleChange}
                data-handle="active_employee"
                aria-label="Default select example"
                // value={state.active_employee}
                className="fs_c_30"
                // style={{ color: COLORS.Dropdown }}
              >
                {permissions.map((em) => (
                  <option key={em.value} value={em.value}>
                    {em.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}
              <div className="mt-1 text-center">
                <Link to="/forgot-password" className="text-muted">
                  <FontAwesomeIcon icon={faLock} size="sm" />
                  <small style={{ marginLeft: 10 }}>Forgot your password?</small>
                </Link>
              </div>
              <Form.Group className="mb-3 pt-3 row">
                <Button type="submit" style={{ width: "50%", margin: "0 auto" }}>
                  Log in
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
