import React from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../../../helpers_components/ErrorFeedback";

import { COLORS } from "../../../config";

export default function SectionThree({ onNextSection, onBackSection, employees, state }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      notes: state.notes || "",
      your_initials: state.your_initials || "",
    },
    validationSchema: Yup.object({
      notes: Yup.string(),
      your_initials: Yup.string().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SectionThree_Crate", values);
      onNextSection("section_3", values);
    },
  });

  const submit = (event) => {
    event.preventDefault();

    val
      .validateForm()
      .then((r) => {
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }
        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    val.resetForm();
  };

  return (
    <Form onSubmit={submit}>
      <Card className="mb-3">
        <Card.Body>
          <h2 className="mb-0">If you chose other for potato quality please include an explanation in the notes</h2>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">Notes</h2>
          <Form.Control
            type="text"
            name="notes"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.notes || ""}
            isInvalid={val.errors.notes ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.notes} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Your Initials <span>*</span>
          </h2>
          <Form.Select
            className="fs_c_20 "
            style={{ color: COLORS.Dropdown }}
            name="your_initials"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.your_initials || ""}
            isInvalid={val.errors.your_initials ? true : false}
          >
            <option value={""}>Choose</option>
            {employees.map((em) => (
              <option key={em.id} value={em.id}>
                {em.initials}
              </option>
            ))}
          </Form.Select>
          <ErrorFeedback error={val.errors.your_initials} />
        </Card.Body>
      </Card>

      <Row>
        <Col md="2">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 next_btn"           
            variant="light"
            onClick={() => onBackSection("section_3")}
          >
            Back
          </Button>
        </Col>
        <Col md="7">
          <Button type="submit" size="sm" className="fs_c_20 " variant="primary">
            Submit
          </Button>
        </Col>
        <Col md="3">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 "
            style={{ padding: "5px 20px", background: "none", color: "rgb(0, 144, 176)", border: "none" }}
            variant="light"
            onClick={clearForm}
          >
            Clear Form
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
