import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";

import { CODE_FLAVOR } from "../../../config";

import { getStateStationDashboard } from "../../../utils/api";

import LoaderFull from "../../../helpers_components/Loader";

const thead = ["ID", "Flavor", "Bag Size", "Batches", "Bags mode", "Totes mode", "Seasoners", "Baggers", "Sealers"];

export default function Seasoning({ from, to }) {
  const [state, setState] = useState({
    loading: false,
    DATA: [],
  });

  useEffect(() => {
    setState({ ...state, loading: true });

    if (!from && !to) {
      getStateStationDashboard("seasoning")
        .then((r) => {
          console.log(r);
          setState({ ...state, DATA: r.states, loading: false });
        })
        .catch((e) => {
          console.log(e);
          setState({ ...state, loading: false });
        });
    }

    if (!from || !to) {
      return;
    }

    getStateStationDashboard("seasoning", from, to)
      .then((r) => {
        console.log(r);
        setState({ ...state, DATA: r.states, loading: false });
      })
      .catch((e) => {
        console.log(e);
        setState({ ...state, loading: false });
      });
  }, [from, to]);

  return (
    <div className="dashboard_section">
      <h2 className="title">Seasoning</h2>
      <div style={{ height: 600, overflow: "auto", position: "relative" }}>
        <LoaderFull show={state.loading} stylesCustom={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
        <ul className="list-unstyled mb-5">
          {state.DATA.map((st) => {
            return (
              <li key={st.start}>
                <div className="d-flex justify-content-around" style={{ backgroundColor: "#00000080", color: "#fff" }}>
                  <h3>Start: {st.start}</h3>
                  <h3>End: {st.end || "in progress"}</h3>
                </div>

                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      {thead.map((th) => (
                        <th key={th}>{th}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {st.state.flavors &&
                      st.state.flavors.map((s) => {
                        let flavorShow = CODE_FLAVOR.find(({ code }) => code === s.flavor);

                        return (
                          <tr key={s.id}>
                            <td>{s.id}</td>
                            <td>{s.flavor} - {flavorShow?.name}</td>
                            <td>{s.bag_size}</td>
                            <td>{s.batches}</td>
                            <td>{s.bags_mode}</td>
                            <td>{s.totes_mode}</td>
                            <td>
                              {s.seasoners && s.seasoners.map((se, ind) => <p key={`${se.name}_${ind}`}>{se.name}</p>)}
                            </td>
                            <td>
                              {s.baggers && s.baggers.map((se, ind) => <p key={`${se.name}_${ind}`}>{se.name}</p>)}
                            </td>
                            <td>
                              {s.sealers && s.sealers.map((se, ind) => <p key={`${se.name}_${ind}`}>{se.name}</p>)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
