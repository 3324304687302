import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

export default function PopupAPIerror({ text, closeModal }) {
  return (
    <div className="popup_container">
      <div className="block">
        <div className="close_button" onClick={closeModal}>
          <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
        </div>
        <h2 className="text-center">API Error</h2>
        {typeof text === "string" && <h4 className="text-center">{text}</h4>}        
      </div>
    </div>
  );
}
