import React, { useEffect, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Line } from "react-chartjs-2";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { cloneDeep } from "lodash";

import { getStateStationDashboard } from "../../../utils/api";
import LoaderFull from "../../../helpers_components/Loader";
import { COMMON_OPTIONS, chartData } from "../../../utils/utils";

const thead = ["Start Station", "Total Cambrios", "Number Buckets", "Final Bucket Weight", "End Station"];

export default function QA({ from, to }) {
  const [state, setState] = useState({
    loading: false,
    DATA: [],
  });

  const tableRef = useRef();

  useEffect(() => {
    setState({ ...state, loading: true });

    if (!from && !to) {
      getStateStationDashboard("qa")
        .then((r) => {
          console.log(r);
          setState({ ...state, DATA: r.states, loading: false });
        })
        .catch((e) => {
          console.log(e);
          setState({ ...state, loading: false });
        });
    }

    if (!from || !to) {
      return;
    }

    getStateStationDashboard("qa", from, to)
      .then((r) => {
        console.log(r);
        setState({ ...state, DATA: r.states, loading: false });
      })
      .catch((e) => {
        console.log(e);
        setState({ ...state, loading: false });
      });
  }, [from, to]);

  const recordsOptions = (records) => {
    const resp = cloneDeep(COMMON_OPTIONS(records));

    resp["plugins"] = {
      datalabels: {
        formatter: function (value, context) {
          return Math.floor(value);
          // return msToTime(Math.floor(value));
        },
        anchor: "end",
        clamp: true,
        clip: true,
        align: "top",
        offset: -5,
        font: { size: 10 },
      },
    };

    return resp;
  };

  let blocktableHeight = 400;
  // if (tableRef.current?.offsetHeight) {
  //   blocktableHeight = tableRef.current?.offsetHeight + 20;
  //   if (tableRef.current.offsetHeight > 400) {
  //     blocktableHeight = 400;
  //   }
  // }

  return (
    <div className="dashboard_section">
      <h2 className="title">QA</h2>
      <div style={{ height: 300 }}>
        <Line data={chartData(state.DATA, "qa")} options={recordsOptions(state.DATA)} plugins={[ChartDataLabels]} />
      </div>
      <div
        style={{
          height: blocktableHeight,
          overflow: "auto",
          position: "relative",
        }}
      >
        <LoaderFull show={state.loading} stylesCustom={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} />

        <Table striped bordered hover variant="dark" ref={tableRef}>
          <thead>
            <tr>
              {thead.map((th) => (
                <th key={th}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.DATA.map((s) => (
              <tr key={s.start}>
                <td>{s.start}</td>
                <td>{s.state.totalCambrios}</td>
                <td>{s.state.number_buckets}</td>
                <td>{s.state.final_bucket_weight}</td>

                <td>{s.end}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
