import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function PopupSelectSKU({ handleChange, options, flavor, pack, closeModal }) {
  return (
    <div className={"popup_container"}>
      <div className={`block select`}>
        <div className="close_button" onClick={closeModal}>
          <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
        </div>

        <h3 className="selectpopup_title">
          <Button data-handle1="delete_sku" variant={"outline-danger"}  onClick={handleChange} size="lg">
            <FontAwesomeIcon data-handle1="delete_sku" size="lg" icon={faTrash} />
          </Button>
          <span className="selectpopup_text">"{flavor}"</span> - <span className="selectpopup_text">"{pack}"</span>
        </h3>

        <Select
          className="ss_select"
          onChange={handleChange}
          options={options}
          styles={{
            menuList: (style) => ({
              ...style,
              backgroundColor: "#ffffff",
              minHeight: 400,
              fontSize: 30,
            }),
          }}
        />
      </div>
    </div>
  );
}
