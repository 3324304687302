import React, { useState, useRef, useEffect } from "react";
import { Button, Form, InputGroup, Row, Badge, Dropdown } from "react-bootstrap";

import { COLORS } from "../../../config";
import CommonFunctionality from "../../../utils/utils";

import LoaderFull from "../../../helpers_components/Loader";
import ManagerPopup from "../../../helpers_components/ManagerPopup";

import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import PopupOilUsed from "../../../helpers_components/PopupsStations/OilUsed";
import PopupFryers from "../../../helpers_components/PopupsStations/Fryers";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import PopupWarning from "../../../helpers_components/PopupsStations/Warning";
import StationInfo from "../../../helpers_components/stationInfo";

export default function Fryer() {
  const snapShotState = useRef({});
  const [loading, setLoading] = useState(true);
  const [managerPopup, setManagerPopup] = useState(false);

  const [warning, setWarning] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [state, setState] = useState({});
  const [popup, setPopup] = useState(null);

  const [stationInfo, setStationInfo] = useState({});

  useEffect(() => {
    CommonFunctionality.getDataStationEmployee(
      "fryer",
      snapShotState,
      setLoading,
      setEmployees,
      setState,
      setStationInfo,
      setManagerPopup
    );
  }, []);
  useEffect(() => {
    CommonFunctionality.updateStatePerChange("fryer", snapShotState, state, setLoading);
  }, [state]);
  const handleChange = ({ target }) => {
    let attr = target.getAttribute("data-handle") || target.parentNode.getAttribute("data-handle");
    let attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");

    const value = target.value;

    if (attr === "increase_bucket") {
      setState((pS) => ({ ...pS, buckets_in_fryer: pS.buckets_in_fryer + 1 }));
      return;
    }
    if (attr === "decrease_bucket") {
      setState((pS) => ({ ...pS, buckets_in_fryer: pS.buckets_in_fryer - 1 }));
      return;
    }

    if (attr.includes("verified")) {
      setState((pS) => ({
        ...pS,
        [attr]: !pS[attr],
      }));
      return;
    }

    setState((pS) => ({ ...pS, [attr]: attr2 === "clear" ? "" : value }));
  };
  const closeManagerPopup = () => setManagerPopup(null);
  const closeModal = () => setPopup(null);
  const handleSaveDataFromPopups = (type, data) => {
    CommonFunctionality.handleSaveDataFromPopups(type, data, setWarning, setManagerPopup, setState, state);
  };

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />
      <ManagerPopup
        show={managerPopup}
        closeManagerPopup={closeManagerPopup}
        station="fryer"
        stationState={state}
        employeesFullList={employees}
      />

      {!state.isStationActive && !managerPopup && <PopupStationNotActive />}
      {stationInfo.end && <PopupStationNotActive text={"This shift has already closed"} />}
      {warning && <PopupWarning text={warning} closeModal={() => setWarning(null)} />}
      {popup === "active_employee" && (
        <PopupActiveEmployee
          employeesFullList={employees}
          employeesListFromDB={state.activeEmployee || []}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "clean_up" && (
        <PopupCleanUp
          employeesFullList={employees}
          clean_ups={state.clean_ups}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
          station={"fry"}
        />
      )}
      {popup === "enter_notes" && (
        <PopupEnterNotes
          employeesFullList={employees}
          enter_notes={state.enter_notes}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "oil_used" && (
        <PopupOilUsed
          bucket_weight_oil={state.bucket_weight_oil}
          bucket_weight_oil_verified={state.bucket_weight_oil_verified}
          handleChangeCustom={handleChange}
          oil_used={state.oil_used}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "fryers" && (
        <PopupFryers
          employeesFullList={employees}
          fryer_notes={state.fryer_notes}
          fryers={state.fryers}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}

      <Dropdown className="drop_down_popups_list">
        <Dropdown.Toggle id="dropdown-basic">Settings</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setPopup("active_employee")}>Active Employee</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("oil_used")}>Oil Used</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("fryers")}>Fryers</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("clean_up")}>Clean Up</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("enter_notes")}>Enter Notes</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <StationInfo stationInfo={stationInfo} />

      <div className="fs_c_40 text-center">
        Fryer Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <Row className="text-center mb-3">
        <div className="fs_c_30 mb-2">
          Assigned Employee Fryer: <span className="label_color">{state.assignedEmployeeFryer?.name}</span>
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Employee Float: <span className="label_color">{state.assignedEmployeeFloat?.name}</span>
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Break Employee Fryer: <span className="label_color">{state.assignedBreakeEmployeeFryer?.name}</span>{" "}
          (<span className="label_color">{state.assignedBreakeEmployeeFryerTime}</span>)
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Break Employee Float: <span className="label_color">{state.assignedBreakeEmployeeFloat?.name}</span>{" "}
          (<span className="label_color">{state.assignedBreakeEmployeeFloatTime}</span>)
        </div>
      </Row>

      <Row>
        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">First Bucket In Fryer:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle="firstBucketInFryerEmployee"
            onChange={handleChange}
            value={state.firstBucketInFryerEmployee}
          />
          <Button
            data-handle="firstBucketInFryerEmployee"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
          <InputGroup.Text className="fs_c_30" style={{ color: "#fb8500" }}>
            {state.firstBucketInFryer}
          </InputGroup.Text>
        </InputGroup>

        <div className="fs_c_30 mb-3 text-center">Buckets In Fryer</div>
        <div className="d-flex flex-row align-items-center mb-3 justify-content-center">
          <Button
            variant="primary"
            size="lg"
            className="mr-2 fs_c_100"
            style={{ marginRight: 30 }}
            data-handle="increase_bucket"
            onClick={handleChange}
          >
            +
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="fs_c_100"
            onClick={handleChange}
            data-handle="decrease_bucket"
            style={{ paddingRight: 30, paddingLeft: 30 }}
          >
            -
          </Button>
          <Badge bg="#EE476F" className="fs_c_100">
            {state.buckets_in_fryer}
          </Badge>
        </div>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Partial (Final) Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle="partial_bucket_weight"
            onChange={handleChange}
            value={state.partial_bucket_weight}
          />
        </InputGroup>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Last Bucket Out Of Fryer:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle="lastBucketOutFryerEmployee"
            onChange={handleChange}
            value={state.lastBucketOutFryerEmployee}
          />
          <Button
            data-handle="lastBucketOutFryerEmployee"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
          <InputGroup.Text className="fs_c_30" style={{ color: "#fb8500" }}>
            {state.lastBucketOutFryer}
          </InputGroup.Text>
        </InputGroup>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Bucket Weight with Chips:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="bucket_weight_with_chips"
            disabled
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.bucket_weight_with_chips}
            onChange={handleChange}
          />
          <Button
            onClick={handleChange}
            className="button_verify"
            data-handle="bucket_weight_with_chips_verified"
            variant={!state.bucket_weight_with_chips_verified ? "danger" : "light"}
          >
            {!state.bucket_weight_with_chips_verified ? "Verify" : "Verified"}
          </Button>
        </InputGroup>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Empty Bucket Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="empty_bucket_weight"
            disabled
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.empty_bucket_weight}
            onChange={handleChange}
          />
          <Button
            onClick={handleChange}
            className="button_verify"
            data-handle="empty_bucket_weight_verified"
            variant={!state.empty_bucket_weight_verified ? "danger" : "light"}
          >
            {!state.empty_bucket_weight_verified ? "Verify" : "Verified"}
          </Button>
        </InputGroup>
      </Row>
    </div>
  );
}
