import React, { useState, useEffect } from "react";
import { Table, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { COLORS } from "../../config";

const HEAD_TABLE = ["Fryer", "Temp", "Filter", "Filtered By", "Status"];

const HEAD_TABLE_NOTES = ["Employee", "Enter Notes", ""];

export default function PopupFryers({
  asBlock,
  fryers,
  fryer_notes = [],
  employeesFullList,
  closeModal,
  handleSaveDataFromPopups,
}) {
  const [fryers_data, setFryers] = useState(fryers || []);
  const [fryers_note_data, setFryers_note] = useState(fryer_notes || []);

  useEffect(() => {
    setFryers(fryers);
  }, [fryers]);
  useEffect(() => {
    setFryers_note(fryer_notes);
  }, [fryer_notes]);

  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2");
    if (!attr1) {
      attr1 = target.parentNode.getAttribute("data-handle1");
    }
    if (!attr2) {
      attr2 = target.parentNode.getAttribute("data-handle2");
    }

    let value = target.value;

    console.log(attr1, attr2, value);

    if (attr1 === "temp") {
      setFryers((pS) =>
        pS.map((et) => {
          if (et.fryer === attr2) {
            return { ...et, [attr1]: value };
          }
          return et;
        })
      );
    }
    if (attr1 === "filteredBy") {
      const getEmpl = employeesFullList.find((em) => +em.value === +value);

      setFryers((pS) =>
        pS.map((et) => {
          if (et.fryer === attr2) {
            return { ...et, [attr1]: getEmpl };
          }
          return et;
        })
      );
    }
    if (attr1 === "active") {
      setFryers((pS) =>
        pS.map((et) => {
          if (et.fryer === attr2) {
            return { ...et, [attr1]: !et[attr1] };
          }
          return et;
        })
      );
    }

    if (attr1 === "add_new_fryer_note") {
      const getEmpl = employeesFullList.find((em) => +em.value === +value);
      setFryers_note((pS) => [...pS, { ...getEmpl, note: "", id: `${getEmpl.value}_${fryers_note_data.length}` }]);
    }
    if (attr1 === "change_note") {
      setFryers_note((pS) =>
        pS.map((et) => {
          if (et.id === attr2) {
            return { ...et, note: value };
          }
          return et;
        })
      );
    }
    if (attr1 === "delete_note") {
      setFryers_note((pS) => pS.filter((emp) => emp.id !== attr2));
    }
  };

  const saveChanges = () => {
    handleSaveDataFromPopups("fryers", fryers_data);
    handleSaveDataFromPopups("fryer_notes", fryers_note_data);
  };

  return (
    <div className={asBlock ? "" : "popup_container"}>
      <div className={`block ${asBlock ? "daily" : ""}`}>
        {!asBlock && (
          <div className="close_button" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#511f2e", padding: 10 }} />
          </div>
        )}
        <div className="fs_c_30 text-center">Fryers</div>

        <Table bordered hover>
          <thead>
            <tr>
              {HEAD_TABLE.map((th) => (
                <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fryers_data.map((st, ind) => (
              <tr key={st.fryer}>
                <td className="clean_ups_title">{st.fryer}</td>
                <td style={{ width: "20%" }}>
                  <Form.Control
                    className="fs_c_30"
                    data-handle1="temp"
                    data-handle2={st.fryer}
                    type="text"
                    style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
                    value={st.temp}
                    onChange={handleChange}
                  />
                </td>
                <td style={{ paddingTop: 10 }}>
                  <InputGroup size="lg" style={{ justifyContent: "center" }}>
                    <Button
                      variant={COLORS.toggleTurns_button}
                      style={{ backgroundColor: st.filtered ? COLORS.NumberPad : COLORS.toggleTurns_button }}
                    >
                      <FontAwesomeIcon icon={st.filtered ? faCheck : faXmark} />
                    </Button>
                  </InputGroup>
                </td>
                <td style={{ textAlign: "center", fontSize: 18 }}>
                  <Form.Select
                    onChange={handleChange}
                    data-handle1="filteredBy"
                    data-handle2={st.fryer}
                    className="fs_c_20"
                    style={{ color: COLORS.Dropdown }}
                    value={st.filteredBy?.value}
                  >
                     <option className="optionPlaceholder" >Select Employee</option>
                    {employeesFullList &&
                      employeesFullList.map((em) => (
                        <option key={em.value} value={em.value}>
                          {em.name}
                        </option>
                      ))}
                  </Form.Select>
                </td>
                <td
                  className="fs_c_20"
                  data-handle1="active"
                  data-handle2={st.fryer}
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: 600,
                    lineHeight: "45px",
                    color: `${st.active ? "green" : "red"}`,
                  }}
                  // onClick={handleChange}
                >
                  {st.active ? "Active" : "Not Active"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="fs_c_30 text-center mb-3">Fryer Notes</div>

        <Table bordered hover>
          <thead>
            <tr>
              {HEAD_TABLE_NOTES.map((th) => (
                <th style={{ textAlign: "center", fontSize: 25 }} key={th}>
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fryers_note_data.map((st, ind) => (
              <tr key={st.id}>
                <td className="clean_ups_title">{st.name}</td>
                <td>
                  <Form.Control
                    className="fs_c_20"
                    data-handle1="change_note"
                    data-handle2={st.id}
                    type="text"
                    style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
                    value={st.note}
                    onChange={handleChange}
                  />
                </td>
                <td
                  data-handle1="delete_note"
                  data-handle2={st.id}
                  onClick={handleChange}
                  style={{ textAlign: "center", fontSize: 18 }}
                >
                  <FontAwesomeIcon
                    data-handle1="delete_note"
                    data-handle2={st.id}
                    icon={faTrash}
                    size="xl"
                    style={{ color: "#ff0000", padding: 10 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Form.Select
          onChange={handleChange}
          data-handle1="add_new_fryer_note"
          className="fs_c_20"
          style={{ color: COLORS.Dropdown }}
        >
           <option className="optionPlaceholder" >Select Employee</option>
          {employeesFullList &&
            employeesFullList.map((em) => (
              <option key={em.value} value={em.value}>
                {em.name}
              </option>
            ))}
        </Form.Select>

        {/* {!asBlock && ( */}
        <Button
          size="lg"
          className={asBlock ? "" : `save_button mt-3`}
          style={{ padding: "10px 20px" }}
          onClick={saveChanges}
        >
          Save Fryer
        </Button>
        {/* )} */}
      </div>
    </div>
  );
}
