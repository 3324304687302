import React, { useState } from 'react';
// import { Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

function Loader(props) {
    return null
    return (
        <Spinner animation="border" role="status" style={{ margin: 'auto', display: 'flex' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

};

export default Loader;
