import React from "react";
import { Button, Form, Row, Dropdown, Col, Card } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../../../helpers_components/ErrorFeedback";

import { COLORS } from "../../../config";

export default function SectionTwo({ onNextSection, onBackSection, employees, state }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      time_chips_out_fryer: state.time_chips_out_fryer || "",
      oil_temp_out_fryer: state.oil_temp_out_fryer || "",
      can_id_1: state.can_id_1 || "",
      can_id_2: state.can_id_2 || "",
      can_weight_1: state.can_weight_1 || "",
      can_weight_2: state.can_weight_2 || "",
      notes: state.notes || "",
      your_initials: state.your_initials || "",
    },
    validationSchema: Yup.object({
      time_chips_out_fryer: Yup.string().required("This is a required field"),
      oil_temp_out_fryer: Yup.string().required("This is a required field"),
      can_id_1: Yup.string().required("This is a required field"),
      can_id_2: Yup.string().required("This is a required field"),
      can_weight_1: Yup.string().required("This is a required field"),
      can_weight_2: Yup.string().required("This is a required field"),
      your_initials: Yup.string().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SectionTwo_FRYER", values);
      onNextSection("section_2", values);
    },
  });

  const submit = (event) => {
    event.preventDefault();

    val
      .validateForm()
      .then((r) => {
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }

        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    val.resetForm();
  };

  return (
    <Form onSubmit={submit}>
      <Card className="mb-3">
        <Card.Header style={{ backgroundColor: "rgb(0, 188, 212)", color: "rgba(0, 0, 0, 1)" }}>
          <h2 className="mb-2 mt-2">Out Of Fryer</h2>
        </Card.Header>
        <Card.Body>
          <h2 className="titles mb-3">
            Time Chips Taken Out Of Fryer <span>*</span>
          </h2>
          <Form.Control
            type="time"
            name="time_chips_out_fryer"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.time_chips_out_fryer || ""}
            isInvalid={val.errors.time_chips_out_fryer ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.time_chips_out_fryer} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Oil Temp When Chips Were Taken Out Of Fryer <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="oil_temp_out_fryer"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.oil_temp_out_fryer || ""}
            isInvalid={val.errors.oil_temp_out_fryer ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.oil_temp_out_fryer} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            1st Can Id <span>*</span>
          </h2>
          <Form.Control
            type="text"
            name="can_id_1"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.can_id_1 || ""}
            isInvalid={val.errors.can_id_1 ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.can_id_1} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            2nd Can Id <span>*</span>
          </h2>
          <Form.Control
            type="text"
            name="can_id_2"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.can_id_2 || ""}
            isInvalid={val.errors.can_id_2 ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.can_id_2} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            1st Can Weight <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="can_weight_1"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.can_weight_1 || ""}
            isInvalid={val.errors.can_weight_1 ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.can_weight_1} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            2nd Can Weight <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="can_weight_2"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.can_weight_2 || ""}
            isInvalid={val.errors.can_weight_2 ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.can_weight_2} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">Notes</h2>
          <Form.Control
            type="text"
            name="notes"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.notes || ""}
            isInvalid={val.errors.notes ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.notes} />
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <h2 className="titles mb-3">
            Your Initials <span>*</span>
          </h2>
          <Form.Select
            className="fs_c_20 "
            style={{ color: COLORS.Dropdown }}
            name="your_initials"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.your_initials || ""}
            isInvalid={val.errors.your_initials ? true : false}
          >
            <option value={""}>Choose</option>
            {employees.map((em) => (
              <option key={em.id} value={em.id}>
                {em.initials}
              </option>
            ))}
          </Form.Select>
          <ErrorFeedback error={val.errors.your_initials} />
        </Card.Body>
      </Card>

      <Row>
        <Col md="2">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 next_btn"
            variant="light"
            onClick={() => onBackSection("section_2")}
          >
            Back
          </Button>
        </Col>
        <Col md="7">
          <Button type="submit" size="sm" className="fs_c_20" variant="primary">
            Submit
          </Button>
        </Col>
        <Col md="3">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 "
            style={{ padding: "5px 20px", background: "none", color: "rgb(0, 144, 176)", border: "none" }}
            variant="light"
            onClick={clearForm}
          >
            Clear Form
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
