import React, { useEffect, useState, useRef } from "react";
import { Col, Table, Row, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { COLORS, MANAGER_INIT } from "../../../config";

import CommonFunctionality from "../../../utils/utils";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import LoaderFull from "../../../helpers_components/Loader";
import { ShiftManage } from "../../../helpers_components/ShiftManage";

const columnsFryers = ["Fryer", "Status", "Filtered"];

export default function Fry({ employees, date }) {
  const snapShotManagerState = useRef(MANAGER_INIT.fry);
  const [state, setState] = useState(MANAGER_INIT.fry);

  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "fryer",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "fryer",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    const attr2 = target.getAttribute("data-handle2");
    const attr3 = target.getAttribute("data-handle3");

    let value = target.value;

    if (value === "on") {
      value = target.checked;
    }

    console.log(attr1, value);
    if (
      attr1 === "assignedEmployeeFryer" ||
      attr1 === "assignedEmployeeFloat" ||
      attr1 === "assignedBreakeEmployeeFryer" ||
      attr1 === "assignedBreakeEmployeeFloat"
    ) {
      const newEmployee = employees.find((em) => +em.value === +value);
      value = newEmployee;
    }

    if (attr1 === "fryers") {
      value = state.fryers.map((fr) => {
        if (fr.fryer === attr3) {
          return { ...fr, [attr2]: !fr[attr2] };
        }
        return fr;
      });
    }

    setState((pS) => ({
      ...pS,
      [attr1]: attr2 === "clear" ? "" : value,
    }));
  };

  const createShift = () => {
    CommonFunctionality.createShift(
      "fryer",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };
  const deleteShift = (shiftId) => {
    CommonFunctionality.deleteShift(
      "fryer",
      shiftId,
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  };

  return (
    <Row className="position-relative">
      <LoaderFull show={loading} />

      <ShiftManage
        createShift={createShift}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={deleteShift}
      />

      <Row className="position-relative">
        {!state.isStationActive && <PopupStationNotActive isManagerPage={true} />}
        {shiftState.end && <PopupStationNotActive isManagerPage={true} text={"Shift has already Closed"} />}
        <Col xs={8}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Assigned Employee Fryer:</InputGroup.Text>
            <Form.Select
              onChange={handleChange}
              data-handle1="assignedEmployeeFryer"
              className="fs_c_20"
              style={{ color: COLORS.Dropdown }}
              value={state.assignedEmployeeFryer?.value}
            >
               <option className="optionPlaceholder" >Select Employee</option>
              {employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Assigned Employee Float:</InputGroup.Text>
            <Form.Select
              onChange={handleChange}
              data-handle1="assignedEmployeeFloat"
              className="fs_c_20"
              style={{ color: COLORS.Dropdown }}
              value={state.assignedEmployeeFloat?.value}
            >
               <option className="optionPlaceholder" >Select Employee</option>
              {employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Assigned Break Employee Fryer:</InputGroup.Text>
            <Form.Select
              onChange={handleChange}
              data-handle1="assignedBreakeEmployeeFryer"
              className="fs_c_20"
              style={{ color: COLORS.Dropdown }}
              value={state.assignedBreakeEmployeeFryer?.value}
            >
               <option className="optionPlaceholder" >Select Employee</option>
              {employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Assigned Break Employee Float:</InputGroup.Text>
            <Form.Select
              onChange={handleChange}
              data-handle1="assignedBreakeEmployeeFloat"
              className="fs_c_20"
              style={{ color: COLORS.Dropdown }}
              value={state.assignedBreakeEmployeeFloat?.value}
            >
               <option className="optionPlaceholder" >Select Employee</option>
              {employees.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Assigned Break Time Fryer:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="text"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              data-handle1="assignedBreakeEmployeeFryerTime"
              onChange={handleChange}
              value={state.assignedBreakeEmployeeFryerTime}
            />
            <Button
              data-handle1="assignedBreakeEmployeeFryerTime"
              data-handle2="clear"
              onClick={handleChange}
              style={{ padding: "0 5px" }}
            >
              Clear
            </Button>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Assigned Break Time Float:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="text"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              data-handle1="assignedBreakeEmployeeFloatTime"
              onChange={handleChange}
              value={state.assignedBreakeEmployeeFloatTime}
            />
            <Button
              data-handle1="assignedBreakeEmployeeFloatTime"
              data-handle2="clear"
              onClick={handleChange}
              style={{ padding: "0 5px" }}
            >
              Clear
            </Button>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">First Bucket In Fryer (Goal Time):</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="time"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              data-handle1="firstBucketInFryer"
              onChange={handleChange}
              value={state.firstBucketInFryer}
            />
            <Button
              data-handle1="firstBucketInFryer"
              data-handle2="clear"
              onClick={handleChange}
              style={{ padding: "0 5px" }}
            >
              Clear
            </Button>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Last Bucket Out of Fryer (Goal Time):</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="time"
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              data-handle1="lastBucketOutFryer"
              onChange={handleChange}
              value={state.lastBucketOutFryer}
            />
            <Button
              data-handle1="lastBucketOutFryer"
              data-handle2="clear"
              onClick={handleChange}
              style={{ padding: "0 5px" }}
            >
              Clear
            </Button>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Bucket Weight with Chips:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="text"
              style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
              data-handle1="bucket_weight_with_chips"
              onChange={handleChange}
              value={state.bucket_weight_with_chips}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Empty Bucket Weight:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="text"
              style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
              data-handle1="empty_bucket_weight"
              onChange={handleChange}
              value={state.empty_bucket_weight}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_20">Bucket Weight Oil:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="text"
              style={{ color: COLORS.DinamicLabel, textAlign: "center" }}
              data-handle1="bucket_weight_oil"
              onChange={handleChange}
              value={state.bucket_weight_oil}
            />
          </InputGroup>
        </Col>

        <Col className="d-flex flex-column align-items-center partial_backet" xs={4}>
          <Table striped={false} bordered={true} hover>
            <thead>
              <tr style={{ textAlign: "center" }}>
                {columnsFryers.map((fr) => (
                  <th
                    key={fr}
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: 600,
                      lineHeight: "55px",
                    }}
                  >
                    {fr}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.fryers.map((fr, index) => (
                <tr key={fr.fryer} style={{ textAlign: "center", alignItems: "center" }}>
                  <td
                    className="fs_c_20"
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: 600,
                      lineHeight: "45px",
                    }}
                  >
                    {fr.fryer}
                  </td>
                  <td
                    className="fs_c_20"
                    data-handle1="fryers"
                    data-handle2="active"
                    data-handle3={`${index + 1}`}
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: 600,
                      lineHeight: "45px",
                      color: `${fr.active ? "green" : "red"}`,
                    }}
                    onClick={handleChange}
                  >
                    {fr.active ? "Active" : "Not Active"}
                  </td>
                  <td style={{ padding: 2 }}>
                    <InputGroup size="lg" style={{ justifyContent: "center" }}>
                      <Button
                        variant={COLORS.toggleTurns_button}
                        data-handle1="fryers"
                        data-handle2="filtered"
                        data-handle3={`${index + 1}`}
                        onClick={handleChange}
                        style={{ backgroundColor: fr.filtered ? COLORS.NumberPad : COLORS.toggleTurns_button }}
                      >
                        <FontAwesomeIcon
                          data-handle1="fryers"
                          data-handle2="filtered"
                          data-handle3={`${index + 1}`}
                          icon={fr.filtered ? faCheck : faXmark}
                        />
                      </Button>
                    </InputGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Row>
  );
}
