import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Tabs, Tab, InputGroup, Form } from "react-bootstrap";

import { isDeepEqual, deepModify, getCurentDate } from "../../utils/utils";
import { getSettings, updateSettings } from "../../utils/api";
import { COLORS } from "../../config";
import LoaderFull from "../../helpers_components/Loader";

import Raw from "./components/Raw";
import Fry from "./components/Fry";
import QA from "./components/QA";
import Seasoning from "./components/Seasoning";
import Kitting from "./components/Kitting";

const LIST_STATIONS = [
  { name: "Raw", id: "raw" },
  { name: "Fry", id: "fry" },
  { name: "QA", id: "qa" },
  { name: "Seasoning", id: "seasoning" },
  { name: "Kitting", id: "kitting" },
];

export default function DailyLog() {
  const snapShotManagerState = useRef({});
  const timerSendManagerState = useRef();

  const [employees, setEmployees] = useState([]);
  const [managerState, setManagerState] = useState({});
  const [loading, setLoading] = useState(false);  
  const [number_of_batches_mode, set_number_of_batches_mode] = useState(0);

  const [shiftST, setShiftST] = useState({
    dateSelected: getCurentDate(),
  });

  useEffect(() => {
    setLoading(true);

    getSettings()
      .then((set) => {
        console.log("SETTINGS DAILY LOG", set);
        setEmployees(set?.settings?.productionEmployee?.assignedEmployees || []);
        setManagerState((pS) => {
          const titi = deepModify(pS, set.settings);
          snapShotManagerState.current = titi;
          return titi;
        });
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (snapShotManagerState.current) {
      if (!isDeepEqual(snapShotManagerState.current, managerState)) {
        clearTimeout(timerSendManagerState.current);
        timerSendManagerState.current = setTimeout(() => {
          onSubmitManager();
        }, 1500);
      }
    }
  }, [managerState]);

  const onSubmitManager = () => {
    setLoading(true);
    console.log("STATE BEFORE SAVE TO API", managerState);
    snapShotManagerState.current = managerState;
    return;
    updateSettings(managerState)
      .then((r) => console.log("UPDATED SUCCESSFULLY FROM DAILY LOG", r))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  // console.log("stateDailyLocal", stateDailyLocal);

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />

      <Row className="justify-content-center">
        <Col xs={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="fs_c_30">Select Date:</InputGroup.Text>
            <Form.Control
              className="fs_c_30"
              type="date"
              max={getCurentDate()}
              style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
              onChange={({ target }) => {
                setShiftST((pS) => ({
                  ...pS,
                  dateSelected: target.value,
                }));
              }}
              value={shiftST.dateSelected}
            />
          </InputGroup>
        </Col>
      </Row>

      {/* <InputGroup className="mb-3">
        <InputGroup.Text className="fs_c_30">Manager Notes for the day:</InputGroup.Text>
        <Form.Control
          className="fs_c_30"
          type="textarea"
          style={{ color: COLORS.Date_timePicker }}
          onChange={({ target }) => {
            setStateDailyLocal({ ...stateDailyLocal, note: target.value });
          }}
          value={stateDailyLocal.note}
        />
      </InputGroup> */}

      <Tabs
        id="uncontrolled-tab-example"
        transition={false}
        fill={false}
        justify={true}
        style={{ fontWeight: "bold", fontSize: 20 }}
      >
        {LIST_STATIONS.map((tab) => (
          <Tab key={tab.id} eventKey={tab.id} title={tab.name} className="tab_container daily">
            {tab.id === "raw" && <Raw employees={employees} date={shiftST.dateSelected} />}
            {tab.id === "fry" && <Fry employees={employees} date={shiftST.dateSelected} />}
            {tab.id === "qa" && (
              <QA
                employees={employees}
                set_number_of_batches_mode={set_number_of_batches_mode}
                date={shiftST.dateSelected}
              />
            )}
            {tab.id === "seasoning" && (
              <Seasoning
                employees={employees}
                number_of_batches_mode={number_of_batches_mode}
                date={shiftST.dateSelected}
              />
            )}
            {tab.id === "kitting" && <Kitting employees={employees} date={shiftST.dateSelected} />}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
