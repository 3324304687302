import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Row, Tabs, Tab, Button, Alert } from "react-bootstrap";

import TabData from "./components/TabData";

import CommonFunctionality, { validationCode } from "../../../utils/utils";

import ManagerPopup from "../../../helpers_components/ManagerPopup";
import ManagerInputForNextFlavor from "../../../helpers_components/ManagerInputForNextFlavor";

import LoaderFull from "../../../helpers_components/Loader";
import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import PopupWarning from "../../../helpers_components/PopupsStations/Warning";
import StationInfo from "../../../helpers_components/stationInfo";

export default function Seasoning() {
  const snapShotState = useRef({});
  const [loading, setLoading] = useState(true);
  const [managerPopup, setManagerPopup] = useState(false);
  const [managerInputNextFlavor, setManagerInputNextFlavor] = useState(false);

  const [warning, setWarning] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [state, setState] = useState({});
  const [popup, setPopup] = useState(null);
  const [action, setAction] = useState("Flavor: 1");
  const [error, setError] = useState(null);

  const [stationInfo, setStationInfo] = useState({});

  useEffect(() => {
    CommonFunctionality.getDataStationEmployee(
      "seasoning",
      snapShotState,
      setLoading,
      setEmployees,
      setState,
      setStationInfo,
      setManagerPopup
    );
  }, []);
  useEffect(() => {
    CommonFunctionality.updateStatePerChange("seasoning", snapShotState, state, setLoading);
  }, [state]);
  const handleChange = ({ target }) => {
    let attr1 = target.getAttribute("data-handle1") || target.parentNode.getAttribute("data-handle1");
    let attr2 = target.getAttribute("data-handle2") || target.parentNode.getAttribute("data-handle2");
    let attr3 = target.getAttribute("data-handle3") || target.parentNode.getAttribute("data-handle3");
    let attr4 = target.getAttribute("data-handle4") || target.parentNode.getAttribute("data-handle4");

    const value = target.value;

    // console.log(attr1, attr2, attr3, attr4, value);

    setError(null);

    const newFlavors = state.flavors.map((f) => {
      if (f.id === attr2) {
        if (attr1 === "code_verified_by" || attr1 === "code_made_by") {
          const newEmployee = employees.find((em) => +em.value === +value);
          return { ...f, [attr1]: newEmployee };
        }

        if (attr1 === "seasoner" || attr1 === "bagger" || attr1 === "sealer") {
          if (attr3 === "delete") {
            return { ...f, [attr1]: f[attr1].filter(({ value }) => +value !== +attr4) };
          }

          const newSeso = employees.find((em) => +em.value === +value);
          return { ...f, [attr1]: [...f[attr1], newSeso] };
        }

        if (attr1 === "wheel_code") {
          return { ...f, [attr1]: value?.toUpperCase() };
        }
        if (attr1 === "increase_bucket") {
          return { ...f, batches_completed: (+f.batches_completed || 0) + 1 };
        }
        if (attr1 === "decrease_bucket") {
          return { ...f, batches_completed: (+f.batches_completed || 0) - 1 };
        }

        return { ...f, [attr1]: attr3 === "clear" ? "" : value };
      }
      return f;
    });

    setState((pS) => ({ ...pS, flavors: newFlavors }));
  };
  const closeManagerPopup = () => setManagerPopup(null);
  const closeModal = () => setPopup(null);
  const handleSaveDataFromPopups = (type, data) => {
    CommonFunctionality.handleSaveDataFromPopups(type, data, setWarning, setManagerPopup, setState, state);
  };
  const handleNextCloseFlavor = () => {
    const activeFlavordata = state.flavors.find(({ name }) => action === name);

    console.log("activeFlavordata", activeFlavordata);
    if (activeFlavordata) {
      if (!activeFlavordata.code_made_by?.value) {
        setError('Please choose "Code Made By" employee');
        return;
      }
      if (!activeFlavordata.code_verified_by?.value) {
        setError('Please choose "Code Verified By" employee');
        return;
      }
      if (!validationCode(activeFlavordata.flavor, activeFlavordata.wheel_code)) {
        setError("Please input correct Wheel Code");
        return;
      }
      if (!activeFlavordata.flavor_end_time) {
        setError("Please input Flavor End Time");
        return;
      }

      setError(null);
      if (action === state.flavors[state.flavors?.length - 1]?.name) {
        setManagerPopup(true);
      } else {
        setManagerInputNextFlavor(true);
      }
    }
  };

  let textButton = state.flavors && action === state.flavors[state.flavors?.length - 1]?.name ? "" : "Next Flavor";

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />
      <ManagerInputForNextFlavor
        show={managerInputNextFlavor}
        handleManager={() => {
          setManagerInputNextFlavor(false);
          setAction((pS) => {
            const name = pS.slice(0, 8);
            const newTabIndex = +pS.slice(8) + 1;
            return `${name}${newTabIndex}`;
          });
        }}
      />
      <ManagerPopup
        show={managerPopup}
        closeManagerPopup={closeManagerPopup}
        station="seasoning"
        stationState={state}
        employeesFullList={employees}
      />

      {!state.isStationActive && !managerPopup && <PopupStationNotActive />}
      {stationInfo.end && <PopupStationNotActive text={"This shift has already closed"} />}
      {warning && <PopupWarning text={warning} closeModal={() => setWarning(null)} />}

      {popup === "active_employee" && (
        <PopupActiveEmployee
          employeesFullList={employees}
          employeesListFromDB={state.activeEmployee}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "clean_up" && (
        <PopupCleanUp
          employeesFullList={employees}
          clean_ups={state.clean_ups}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
          station={"seasoning"}
        />
      )}
      {popup === "enter_notes" && (
        <PopupEnterNotes
          employeesFullList={employees}
          enter_notes={state.enter_notes}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}

      <Dropdown className="drop_down_popups_list">
        <Dropdown.Toggle id="dropdown-basic">Settings</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setPopup("active_employee")}>Active Employee</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("clean_up")}>Clean Up</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("enter_notes")}>Enter Notes</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <StationInfo stationInfo={stationInfo} />

      <div className="fs_c_40 text-center">
        Seasoning Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <Row className="text-center mb-3">
        {state.assignedEmployees &&
          state.assignedEmployees.map((em) => (
            <div className="fs_c_30 mb-2" key={`${em.name}_${em.time}`}>
              Assigned Employee: <span className="label_color">{em.name}</span> (
              <span className="label_color">{em.time}</span>)
            </div>
          ))}
      </Row>

      <Row className="mt-6">
        <Tabs
          defaultActiveKey={action}
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={action}
          transition={false}
          fill={false}
          justify={true}
          style={{ fontWeight: "bold", fontSize: 20 }}
        >
          {state.flavors &&
            state.flavors.map((tab) => (
              <Tab key={tab.name} eventKey={tab.name} title={tab.name} className="tab_container">
                <TabData state={tab} handleChange={handleChange} employees={employees} />
              </Tab>
            ))}
        </Tabs>
      </Row>

      {textButton && (
        <Row className="justify-content-center">
          {error && (
            <Alert key={"danger"} variant="danger">
              {error}
            </Alert>
          )}
          <Button
            size="lg"
            className="mt-1"
            style={{ padding: "10px 20px", fontSize: 30 }}
            onClick={handleNextCloseFlavor}
          >
            {textButton}
          </Button>
        </Row>
      )}
    </div>
  );
}
