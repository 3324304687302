import React from "react";

import { createStationShift, getEmployees, openStationShift, updateStateStation } from "../utils/api";
import CommonFunctionality, { deepModify, getCurentDate, get_data_per_station, isDeepEqual } from "../utils/utils";

export class Station extends React.PureComponent {
  constructor(props, section, titleSection, dataState) {
    super(props);
    this.date = getCurentDate(); 
    this.section = section;
    this.titleSection = titleSection;

    this.state = {
      popup: null,
      employees: [],
      shiftState: { activeShift: 0, list: [] },
      data: dataState || {},
      key: "section_1",
      loading: false,
    };

    this.submit = this.submit.bind(this);
    this.onNextSection = this.onNextSection.bind(this);
    this.onBackSection = this.onBackSection.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    get_data_per_station(this.section, this.date, this.state.shiftState.activeShift)
      .then((rr) => {
        console.log(rr);

        this.setState((ps) => {
          const newState = { ...ps };

          if (rr.empl && rr.empl.list.length > 0) {
            const modList = rr.empl.list.map((empl) => ({
              // initials: empl.first_name.slice(0, 1) + empl.last_name.slice(0, 1),
              initials: empl.first_name + " " + empl.last_name,
              id: empl.id,
            }));
            newState.employees = modList;
          }

          if (rr.shifts && rr.shifts.length > 0) {
            newState.shiftState = { ...newState.shiftState, list: rr.shifts };
          }

          if (rr.station && rr.station.state) {
            newState.data = deepModify(this.state.data, rr.station.state);
          }

          return newState;
        });
      })
      .catch((e) => {       
        if (e.msg === "The shift doesn't exist on this day") {
          createStationShift(this.section, this.date, { state: this.state.data }).then((r) => {
            console.log("createStationShift", r);
            getEmployees().then((rr) => {
              if (rr && rr.list.length > 0) {
                const modList = rr.list.map((empl) => ({
                  // initials: empl.first_name.slice(0, 1) + empl.last_name.slice(0, 1),
                  initials: empl.first_name + " " + empl.last_name,
                  id: empl.id,
                }));
                this.setState({ employees: modList });
              }
            });
          });
        }
        // if (e.msg === "Should open the station first") {
        //   openStationShift(this.section, this.date, {}).then((r) => {
        //     console.log("openStationShift", r);
        //   });
        // }
      });
  }

//   componentDidUpdate(prevPr, prevSt) {
//     console.log("prevSt", prevSt);
//     console.log("this.state", this.state);   
//   }

  submit() {
    console.log("SUBMIT");

    updateStateStation(this.section, this.state.data, this.date, this.state.shiftState.activeShift)
      .then((r) => {
        console.log(this.section, "updateStateStation", r);
      })
      .catch((e) => {
        console.log(e);
        // if (e.msg === "The shift doesn't exist on this day") {
        //   createStationShift(this.section, this.date, { state: this.state.data }).then((r) => {
        //     console.log("createStationShift", r);
        //   });
        // }
        if (e.msg === "Should open the station first") {
          openStationShift(this.section, this.date, {}).then((r) => {
            console.log("openStationShift", r);
            updateStateStation(this.section, this.state.data, this.date, this.state.shiftState.activeShift).then(
              (r) => {
                console.log(this.section, "updateStateStation", r);
              }
            );
          });
        }
      });
  }

  onNextSection(section, values) {
    const sections = Object.keys(this.state.data);

    let sectionSplit = section.split("_");
    let nextSection = null;
    if (+sectionSplit[1] < sections.length) {
      nextSection = sectionSplit[0] + "_" + (+sectionSplit[1] + 1);
    }

    this.setState(
      (ps) => {
        const newState = { ...ps, data: { ...ps.data, [section]: values } };
        if (nextSection) {
          newState.key = nextSection;
        }
        return newState;
      },
      () => {
        let isReadySubmit = true;

        for (let i = 0; i < sections.length; i++) {
          if (Object.keys(this.state.data[sections[i]]).length === 0) {
            isReadySubmit = false;
            break;
          }
        }

        !nextSection && isReadySubmit && this.submit();
      }
    );
  }

  onBackSection(sectionFrom) {
    let nextSection = sectionFrom.split("_");
    nextSection = nextSection[0] + "_" + (+nextSection[1] - 1);
    this.setState({ key: nextSection });
  }

  toggleModal(popupName) {
    this.setState({ popup: popupName });
  }
}
