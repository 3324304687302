import React from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../../../helpers_components/ErrorFeedback";

export default function SectionTwo({ onNextSection, onBackSection, state }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      potatoes_for_tomorrow: state.potatoes_for_tomorrow || "",
    },
    validationSchema: Yup.object({
      potatoes_for_tomorrow: Yup.string().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SectionTwo_Crate", values);
      onNextSection("section_2", values);
    },
  });

  const submit = (event) => {
    event.preventDefault();

    val
      .validateForm()
      .then((r) => {
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }
        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearForm = () => {
    val.resetForm();
  };

  return (
    <Form onSubmit={submit}>
      <Card className="mb-3">
        <Card.Header style={{ backgroundColor: "rgb(0, 188, 212)", color: "rgba(0, 0, 0, 1)" }}>
          <h2 className="mb-2 mt-2">Potatoes for tomorrow</h2>
        </Card.Header>
        <Card.Body>
          <h2 className="titles mb-3">
            How many pounds of potatoes were prepped and saved for tomorrow? <span>*</span>
          </h2>
          <Form.Control
            type="number"
            name="potatoes_for_tomorrow"
            onChange={val.handleChange}
            onBlur={val.handleBlur}
            value={val.values.potatoes_for_tomorrow || ""}
            isInvalid={val.errors.potatoes_for_tomorrow ? true : false}
          ></Form.Control>
          <ErrorFeedback error={val.errors.potatoes_for_tomorrow} />
        </Card.Body>
      </Card>

      <Row>
        <Col md="2">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 next_btn"
            variant="light"
            onClick={() => onBackSection("section_2")}
          >
            Back
          </Button>
        </Col>
        <Col md="7">
          <Button type="submit" size="sm" className="fs_c_20 next_btn" variant="light">
            Next
          </Button>
        </Col>
        <Col md="3">
          <Button
            type="button"
            size="sm"
            className="fs_c_20 "
            style={{ padding: "5px 20px", background: "none", color: "rgb(0, 144, 176)", border: "none" }}
            variant="light"
            onClick={clearForm}
          >
            Clear Form
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
