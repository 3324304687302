import { useState } from "react";

export default function useUser() {
  const getUser = () => {
    const userString = localStorage.getItem("user");
    const userData =
      userString && userString !== "undefined" ? JSON.parse(userString) : {};
    return userData;
  };

  const [user, setUser] = useState(getUser());

  const saveUser = (userData) => {
    if (userData) {
      localStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);
    }
  };

  return {
    setUser: saveUser,
    user,
  };
}
