import React, { useEffect, useState } from "react";

import { Row, Table } from "react-bootstrap";
import { Status } from "./StatusPoint";

export default function Flavors({ data, columns, estate, isLoading }) {
  const [state, setState] = useState({ list: [], classes: { fl: "flavor_item", val: "value_item" } });

  useEffect(() => {
    setState((pS) => ({
      ...pS,
      list: data,
      classes: { fl: `flavor_item${estate ? "" : " limited"}`, val: `value_item${estate ? "" : " limited"}` },
    }));
  }, [data]);

  // console.log("Flavors", state.list);

  return (
    <>
      <Row className="dashboard_monitor_container">
        <Table striped bordered>
          <thead>
            <tr>
              {columns.map((th) => (
                <th key={th} className="head_item">
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(state.list).map((st) => {
              return (
                <tr key={st.code} className={`row_cc${estate ? "" : " limited"}`}>
                  <td
                    className={state.classes.fl}
                    style={{ backgroundColor: state.list[st]?.color || "#000000", color: "#FFFFFF" }}
                  >
                    {st}
                  </td>

                  {columns
                    .filter((pack) => pack !== "ESTATE FLAVOR")
                    .map((pack) => {
                      return (
                        <td className={state.classes.val} key={`${st}_${pack}`}>
                          {state.list[st][pack]?.quantity}
                          <Status status={state.list[st][pack]?.quantity} isLoading={isLoading} />
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
}
