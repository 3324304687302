import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Row, Dropdown } from "react-bootstrap";

import { COLORS } from "../../../config";
import CommonFunctionality from "../../../utils/utils";

import LoaderFull from "../../../helpers_components/Loader";
import ManagerPopup from "../../../helpers_components/ManagerPopup";
import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import PopupStationNotActive from "../../../helpers_components/PopupsStations/StationNotActive";
import PopupWarning from "../../../helpers_components/PopupsStations/Warning";
import StationInfo from "../../../helpers_components/stationInfo";

export default function Raw() {
  const snapShotState = useRef({});
  const [loading, setLoading] = useState(true);
  const [managerPopup, setManagerPopup] = useState(false);

  const [warning, setWarning] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [state, setState] = useState({});
  const [popup, setPopup] = useState(null);

  const [stationInfo, setStationInfo] = useState({});

  useEffect(() => {
    CommonFunctionality.getDataStationEmployee(
      "raw",
      snapShotState,
      setLoading,
      setEmployees,
      setState,
      setStationInfo,
      setManagerPopup
    );
  }, []);
  useEffect(() => {
    CommonFunctionality.updateStatePerChange("raw", snapShotState, state, setLoading);
  }, [state]);
  const handleChange = ({ target }) => {
    const attr = target.getAttribute("data-handle");
    const attr2 = target.getAttribute("data-handle2");
    const value = target.value;
    if (attr.includes("verified")) {
      setState((pS) => ({
        ...pS,
        [attr]: !pS[attr],
      }));
      return;
    }
    setState((pS) => ({ ...pS, [attr]: attr2 === "clear" ? "" : value }));
  };
  const closeManagerPopup = () => setManagerPopup(null);
  const closeModal = () => setPopup(null);
  const handleSaveDataFromPopups = (type, data) => {
    CommonFunctionality.handleSaveDataFromPopups(type, data, setWarning, setManagerPopup, setState, state);
  };

  return (
    <div className="position-relative mb-6">
      <LoaderFull show={loading} />
      <ManagerPopup
        show={managerPopup}
        closeManagerPopup={closeManagerPopup}
        station="raw"
        stationState={state}
        employeesFullList={employees}
      />
      {!state.isStationActive && !managerPopup && <PopupStationNotActive />}
      {stationInfo.end && <PopupStationNotActive text={"This shift has already closed"} />}
      {warning && <PopupWarning text={warning} closeModal={() => setWarning(null)} />}
      {popup === "active_employee" && (
        <PopupActiveEmployee
          employeesFullList={employees}
          employeesListFromDB={state.activeEmployee || []}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}
      {popup === "clean_up" && (
        <PopupCleanUp
          employeesFullList={employees}
          clean_ups={state.clean_ups}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
          station={"raw"}
        />
      )}
      {popup === "enter_notes" && (
        <PopupEnterNotes
          employeesFullList={employees}
          enter_notes={state.enter_notes}
          closeModal={closeModal}
          handleSaveDataFromPopups={handleSaveDataFromPopups}
        />
      )}

      <Dropdown className="drop_down_popups_list">
        <Dropdown.Toggle id="dropdown-basic">Settings</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setPopup("active_employee")}>Active Employee</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("clean_up")}>Clean Up</Dropdown.Item>
          <Dropdown.Item onClick={() => setPopup("enter_notes")}>Enter Notes</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <StationInfo stationInfo={stationInfo} />

      <div className="fs_c_40 text-center">
        Raw Station <span className="success_color">{state.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <Row className="text-center">
        <div className="fs_c_30 mb-2">
          Goal (lbs): <span className="label_color">{state.bucket_goal}</span>
        </div>
        <div className="fs_c_30 mb-2">
          Container Type: <span className="label_color">{state.containerType}</span>
        </div>
        <div className="fs_c_30 mb-2">
          Number of Containers: <span className="label_color">{state.numberOfContainers}</span>
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Employee: <span className="label_color">{state.assignedEmployee?.name}</span>
        </div>
        <div className="fs_c_30 mb-2">
          Assigned Break Employee: <span className="label_color">{state.assignedBreakeEmployee?.name}</span>
        </div>
        <div className="fs_c_30">
          Assigned Break Time: <span className="label_color">{state.assignedBreakeTime}</span>
        </div>
      </Row>

      <Row>
        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Number of Containers Completed:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="number"
            style={{ color: COLORS.NumberPad, textAlign: "end" }}
            data-handle="number_of_containers_completed"
            value={state.number_of_containers_completed}
            onChange={handleChange}
          />
        </InputGroup>
        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">First Potatoes To Fry Station:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            data-handle="firstPotatoesToFryEmployee"
            onChange={handleChange}
            value={state.firstPotatoesToFryEmployee}
          />
          <Button
            data-handle="firstPotatoesToFryEmployee"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
          <InputGroup.Text className="fs_c_30" style={{ color: "#fb8500" }}>
            {state.firstPotatoesToFry}
          </InputGroup.Text>
        </InputGroup>
        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Last Potatoes To Fry Station:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="lastPotatoesToFryEmployee"
            type="time"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.lastPotatoesToFryEmployee}
            onChange={handleChange}
          />
          <Button
            data-handle="lastPotatoesToFryEmployee"
            data-handle2="clear"
            onClick={handleChange}
            style={{ padding: "0 5px" }}
          >
            Clear
          </Button>
          <InputGroup.Text className="fs_c_30" style={{ color: "#fb8500" }}>
            {state.lastPotatoesToFry}
          </InputGroup.Text>
        </InputGroup>

        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Container Weight With Potatoes:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="container_weight_with_potatoes"
            disabled
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.container_weight_with_potatoes}
            onChange={handleChange}
          />
          <Button
            onClick={handleChange}
            className="button_verify"
            data-handle="container_weight_with_potatoes_verified"
            variant={!state.container_weight_with_potatoes_verified ? "danger" : "light"}
          >
            {!state.container_weight_with_potatoes_verified ? "Verify" : "Verified"}
          </Button>
        </InputGroup>
        <InputGroup className="mb-4">
          <InputGroup.Text className="fs_c_30">Empty Container Weight:</InputGroup.Text>
          <Form.Control
            className="fs_c_30"
            data-handle="empty_container_weight"
            disabled
            type="text"
            style={{ color: COLORS.Date_timePicker, textAlign: "end" }}
            value={state.empty_container_weight}
            onChange={handleChange}
          />
          <Button
            onClick={handleChange}
            className="button_verify"
            data-handle="empty_container_weight_verified"
            variant={!state.empty_container_weight_verified ? "danger" : "light"}
          >
            {!state.empty_container_weight_verified ? "Verify" : "Verified"}
          </Button>
        </InputGroup>
      </Row>
    </div>
  );
}
