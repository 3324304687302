import React, { useEffect, useState } from "react";

import { Col, Row, Card, Form, InputGroup, Stack } from "react-bootstrap";
import { getEmployees } from "../../utils/api";
import { getPeriodDefault, countPeriod } from "../../utils/utils";
import { periods, COLORS } from "../../config";

const period = getPeriodDefault();

export default function EmployeCheck(props) {
  const [state, setState] = useState({
    employees: [],
    period: "month",
    from: period.from,
    to: period.to,
    employee_id: "",
  });

  const handleChange = (e) => {
    const v = e.target.value;
    const n = e.target.name;
    setState((pS) => {
      const newState = { ...pS };
      if (n === "period" && v !== "custom") {
        const { from, to } = countPeriod(v);
        newState["from"] = from;
        newState["to"] = to;
      }
      newState[n] = v;
      return newState;
    });
  };

  useEffect(() => {
    getEmployees()
      .then((r) => {
        console.log(r);
        setState({ ...state, employees: r.list });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Row className="mb-3 mt-2">
        <Col xs="3">
          <Card.Body>
            <Form.Select
              className="fs_c_20 "
              name="employee_id"
              onChange={handleChange}
              value={state.employee_id || ""}
            >
              <option className="optionPlaceholder" value={""}>
                Select Employee
              </option>
              {state.employees.map((em) => (
                <option key={em.id} value={em.id}>
                  {em.first_name} {em.last_name}
                </option>
              ))}
            </Form.Select>
          </Card.Body>
        </Col>

        <Col xs="3">
          <Card.Body>
            <Form.Select className="fs_c_20 " name="period" onChange={handleChange} value={state.period}>
              <option className="optionPlaceholder" value={""}>
                Select Period
              </option>
              {periods.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
          </Card.Body>
        </Col>

        <Col className="row justify-content-md-center">
          <Stack direction="horizontal" gap={3}>
            <Card.Body>
              <Form.Control
                className="fs_c_20 text-end text-primary"
                type="date"
                name="from"
                onChange={handleChange}
                value={state.from}
                disabled={state.period !== "custom"}
              />
            </Card.Body>
            <div className="dividerDate"></div>
            <Card.Body>
              <Form.Control
                className="fs_c_20 text-end text-primary"
                type="date"
                name="to"
                onChange={handleChange}
                value={state.to}
                disabled={state.period !== "custom"}
              />
            </Card.Body>
          </Stack>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <h2 className="fw-bolder fs-2 mb-5 mt-2 lh-1 text-decoration-underline">Performance Report</h2>
          <Row className="border-bottom mb-3">
            <Col>
              <h2 className="fs-3">Shift</h2>
            </Col>
            <Col>
              <h2 className="fs-4">optimal</h2>
            </Col>
            <Col>{/* <h2 className="fs-4">Shift</h2> */}</Col>
          </Row>
          <Row className="border-bottom mb-3">
            <Col>
              <h2 className="fs-3">Breaks</h2>
            </Col>
            <Col>
              <h2 className="fs-4">optimal</h2>
            </Col>
            <Col>{/* <h2 className="fs-4">Shift</h2> */}</Col>
          </Row>
          <Row className="border-bottom mb-3">
            <Col>
              <h2 className="fs-3">Lunch</h2>
            </Col>
            <Col>
              <h2 className="fs-4">Needs Work</h2>
            </Col>
            <Col>{/* <h2 className="fs-4">Shift</h2> */}</Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
