import React, { useEffect, useState, useRef } from "react";
import { Row, InputGroup, Form, Button } from "react-bootstrap";

import { COLORS, MANAGER_INIT } from "../../../config";
import CommonFunctionality, { CalcParams } from "../../../utils/utils";
import PopupActiveEmployee from "../../../helpers_components/PopupsStations/ActiveEmployee";
import PopupCleanUp from "../../../helpers_components/PopupsStations/CleanUp";
import PopupEnterNotes from "../../../helpers_components/PopupsStations/EnterNotes";
import { ShiftManage } from "../../../helpers_components/ShiftManage";
import LoaderFull from "../../../helpers_components/Loader";

export default function RawDaily({ employees, date }) {
  const snapShotManagerState = useRef(MANAGER_INIT.raw);

  const [state, setState] = useState(MANAGER_INIT.raw);
  const [shiftState, setShiftState] = useState({ activeShift: 0, list: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CommonFunctionality.getDataStationManager(
      "raw",
      snapShotManagerState,
      date,
      shiftState.activeShift,
      setLoading,
      setShiftState,
      setState
    );
  }, [date, shiftState.activeShift]);

  useEffect(() => {
    CommonFunctionality.updateStatePerChange(
      "raw",
      snapShotManagerState,
      state,
      setLoading,
      date,
      shiftState.activeShift
    );
  }, [state]);

  const handleChange = ({ target }) => {
    const attr1 = target.getAttribute("data-handle1");
    const attr2 = target.getAttribute("data-handle1");

    let value = target.value;

    console.log(attr1, attr2, value);

    if (attr1.includes("verified")) {
      setState((pS) => ({
        ...pS,
        [attr1]: !pS[attr1],
      }));
      return;
    }

    setState((pS) => ({
      ...pS,
      [attr1]: attr2 === "clear" ? "" : value,
    }));
  };

  const handleSaveDataFromPopups = (type, data) => {
    console.log(type, data);
    setState((pS) => ({ ...pS, [type]: data }));
  };

  return (
    <>
      <div className="fs_c_40 text-center">
        Raw Station <span className="success_color">{state?.isStationActive ? "Active" : "Not active"}</span>
      </div>

      <LoaderFull show={loading} />

      <ShiftManage
        createShift={null}
        shiftState={shiftState}
        setShiftState={setShiftState}
        handleChange={handleChange}
        isStationActive={state.isStationActive}
        deleteShift={() => {}}
      />
      {shiftState.list.length > 0 && (
        <>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Pounds of Raw:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "pounds_of_raw")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Total Minutes:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "totalMinutes")}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Employees:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                readOnly
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={CalcParams(state, "number_of_employees")}
              />
            </InputGroup>
          </Row>
          <h3 className="fs_c_30 text-center">Process</h3>
          <Row className="">
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Number of Containers Completed:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="number_of_containers_completed"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.number_of_containers_completed || ""}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">First Bucket To Fry Station:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="time"
                data-handle1="firstPotatoesToFryEmployee"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.firstPotatoesToFryEmployee || ""}
                onChange={handleChange}
              />
              <Button
                data-handle1="firstPotatoesToFryEmployee"
                data-handle2="clear"
                onClick={handleChange}
                style={{ padding: "0 5px" }}
              >
                Clear
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Last Bucket To Fry Station:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="time"
                data-handle1="firstPotatoesToFryEmployee"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.lastPotatoesToFryEmployee || ""}
                onChange={handleChange}
              />
              <Button
                data-handle1="lastPotatoesToFryEmployee"
                data-handle2="clear"
                onClick={handleChange}
                style={{ padding: "0 5px" }}
              >
                Clear
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Container Weight With Potatoes:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="container_weight_with_potatoes"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.container_weight_with_potatoes || ""}
                onChange={handleChange}
              />
              <Button
                onClick={handleChange}
                className="button_verify"
                data-handle1="container_weight_with_potatoes_verified"
                variant={!state.container_weight_with_potatoes_verified ? "danger" : "light"}
              >
                {!state.container_weight_with_potatoes_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Empty Container Weight:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="number"
                data-handle1="empty_container_weight"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.empty_container_weight || ""}
                onChange={handleChange}
              />
              <Button
                onClick={handleChange}
                data-handle1="empty_container_weight_verified"
                className="button_verify"
                variant={!state.empty_container_weight_verified ? "danger" : "light"}
              >
                {!state.empty_container_weight_verified ? "Verify" : "Verified"}
              </Button>
            </InputGroup>
          </Row>
          <PopupActiveEmployee
            asBlock={true}
            employeesFullList={employees}
            employeesListFromDB={state?.activeEmployee}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />
          <PopupCleanUp
            asBlock={true}
            employeesFullList={employees}
            clean_ups={state?.clean_ups}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
            station={"raw"}
          />
          <PopupEnterNotes
            asBlock={true}
            employeesFullList={employees}
            enter_notes={state?.enter_notes}
            handleSaveDataFromPopups={handleSaveDataFromPopups}
          />
          <Row>
            <InputGroup className="mb-3">
              <InputGroup.Text className="fs_c_20">Manager Notes for Raw:</InputGroup.Text>
              <Form.Control
                className="fs_c_30"
                type="text"
                data-handle1="manager_notes"
                style={{ color: COLORS.DinamicLabel, textAlign: "end" }}
                value={state?.manager_notes || ""}
                onChange={handleChange}
              />
            </InputGroup>
          </Row>{" "}
        </>
      )}
    </>
  );
}
