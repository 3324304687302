import React, { useEffect, useState } from "react";
import { Col, Row, Table, Form } from "react-bootstrap";

import { getDashboardFlavorsPack, updateDashboardFlavorsPack, getInventorySKU } from "../../../utils/api";
import PopupSelectSKU from "../../../helpers_components/PopupsStations/SelectPopup";
import LoaderFull from "../../../helpers_components/Loader";

export default function SKU_Manager({setError}) {
  const [state, setState] = useState({
    sku_list: [],
    table_data: {},
    activeCell: { flavor: null, pack: null, sku: null },
    columns: [],
    loading: false,
  }); 

  useEffect(() => {
    getDATA();
  }, []);

  function getDATA() {
    const allPromises = [
      new Promise((res, rej) => {
        try {
          res(getDashboardFlavorsPack());
        } catch (error) {
          rej(error);
        }
      }),
      new Promise((res, rej) => {
        try {
          res(getInventorySKU());
        } catch (error) {
          rej(error);
        }
      }),
    ];

    Promise.all(allPromises)
      .then((r) => {
        console.log("SKU_Manager", r);

        const fls = Object.keys(r[0]);
        const columns = Object.keys(r[0][fls[0]]);
        const cl = columns.filter((c) => c !== "color" && c !== "estate").reverse();
        cl.unshift("ESTATE FLAVOR");

        setState((pS) => ({
          ...pS,
          sku_list: r[1].map((sku) => ({ value: sku, label: sku })),
          table_data: r[0],
          columns: cl,
        }));
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      });
  }

  const handleChange = (e, flavor, pack) => {
    let attr3 = e.target?.getAttribute("data-handle1") || e.target?.parentNode?.getAttribute("data-handle1");
    setState((pS) => ({ ...pS, loading: true }));
    // console.log(e, flavor, pack, attr3);
    // return;
    updateDashboardFlavorsPack({
      flavor: flavor,
      pack: pack,
      sku: attr3 && attr3 === "delete_sku" ? null : e.value,
    })
      .then((r) => {
        console.log("UPDATED FLAVOR PACK ", r);

        setState((pS) => ({
          ...pS,
          activeCell: { flavor: null, pack: null, sku: null },
          loading: false,
          table_data: {
            ...pS.table_data,
            [r.flavor]: {
              ...pS.table_data[r.flavor],
              [r.pack]: { ...pS.table_data[r.flavor][r.pack], sku: r.sku, quantity: r.quantity },
            },
          },
        }));
      })
      .catch((e) => {
        console.log("updateDashboardFlavorsPack", e);
        setError(e);
        setState((pS) => ({ ...pS, activeCell: { flavor: null, pack: null, sku: null }, loading: false }));
      });
  };

  return (
    <>
      <LoaderFull show={state.loading} />      
      {state.activeCell.flavor && (
        <PopupSelectSKU
          handleChange={(e) => handleChange(e, state.activeCell.flavor, state.activeCell.pack)}
          options={state.sku_list}
          flavor={state.activeCell.flavor}
          pack={state.activeCell.pack}
          closeModal={() =>
            setState((pS) => ({ ...pS, activeCell: { flavor: null, pack: null, sku: null }, loading: false }))
          }
        />
      )}
      <Row className="dashboard_monitor_container mt-1" style={{ marginLeft: 5, marginRight: 5 }}>
        <Table striped bordered>
          <thead>
            <tr>
              {state.columns.map((th) => (
                <th key={th} className="head_item_manager">
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(state.table_data).map((st) => {
              return (
                <tr key={st} className="">
                  <td
                    className="flavor_item manager"
                    style={{
                      backgroundColor: state.table_data[st]?.color || "#000000",
                      color: "#FFFFFF",
                      fontSize: 12,
                    }}
                  >
                    {st}
                  </td>

                  {state.columns
                    .filter((pack) => pack !== "ESTATE FLAVOR")
                    .map((pack) => {
                      return (
                        <td
                          className="value_item manager"
                          key={`${st}_${pack}`}
                          onClick={() => {
                            setState((pS) => ({ ...pS, activeCell: { flavor: st, pack: pack, sku: null } }));
                          }}
                        >
                          {state.table_data[st][pack]?.sku}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
}
