import React from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";

import ErrorFeedback from "../ErrorFeedback";

import { sign_options_modal } from "../../config";

export default function PopupSignInOut({ employees = [], closeModal }) {
  const val = useFormik({
    enableReinitialize: true,
    initialValues: {
      employee_id: "",
      action: "",
      notes: "",
    },
    validationSchema: Yup.object({
      employee_id: Yup.string().required("This is a required field"),
      action: Yup.string().required("This is a required field"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit_SignInOut", values);
      closeModal(null);
    },
  });

  const submit = (event) => {
    event.preventDefault();
    val
      .validateForm()
      .then((r) => {
        if (JSON.stringify(r) !== "{}") {
          console.log("valid bad");
          return;
        }
        val.handleSubmit();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="popup_container">
      <div className="block sign">
        <div className="close_button" onClick={() => closeModal(null)}>
          <FontAwesomeIcon icon={faCircleXmark} size="2xl" />
        </div>
        <h2 className="text-center">Sign In/Out</h2>
        <Form onSubmit={submit}>
          {/* <Card className="mb-3"> */}
          <Card.Body className="mb-3">
            {/* <h2 className="titles mb-3">
                Employee Name <span>*</span>
              </h2> */}
            <Form.Select
              className="fs_c_20 "
              name="employee_id"
              onChange={val.handleChange}
              onBlur={val.handleBlur}
              value={val.values.employee_id || ""}
              isInvalid={val.errors.employee_id ? true : false}
            >
              <option value={""}>Choose employee</option>
              {employees.map((em) => (
                <option key={em.id} value={em.id}>
                  {em.initials}
                </option>
              ))}
            </Form.Select>
            <ErrorFeedback error={val.errors.employee_id} />
          </Card.Body>
          {/* </Card> */}

          <Card.Body className="mb-3">
            {/* <h2 className="titles mb-3">
                Employee Name <span>*</span>
              </h2> */}
            <Form.Select
              className="fs_c_20 "
              name="action"
              onChange={val.handleChange}
              onBlur={val.handleBlur}
              value={val.values.action || ""}
              isInvalid={val.errors.action ? true : false}
            >
              <option value={""}>Choose option</option>
              {sign_options_modal.map((em) => (
                <option key={em.value} value={em.value}>
                  {em.name}
                </option>
              ))}
            </Form.Select>
            <ErrorFeedback error={val.errors.action} />
          </Card.Body>

          {/* <Card className="mb-3"> */}
          <Card.Body className="mb-3">
            {/* <h2 className="titles mb-3">Notes (optional)</h2> */}
            <Form.Control
              className="fs_c_20 "
              type="text"
              as="textarea"
              rows={3}
              name="notes"
              placeholder="Notes (optional)"
              onChange={val.handleChange}
              onBlur={val.handleBlur}
              value={val.values.notes || ""}
              isInvalid={val.errors.notes ? true : false}
            ></Form.Control>
            <ErrorFeedback error={val.errors.notes} />
          </Card.Body>
          {/* </Card> */}

          <Row>
            <Button type="submit" size="sm" className="fs_c_20 " variant="primary">
              Submit
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
}
